import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify'
import { history } from "../..";
import InputErrorMessage from '../components/inputErrorMessage';
import LoadingComponent from '../../app/layout/LoadingComponent';

interface Props {
  handleClose: any;
  elementId: string;
  vehicleType: string;
}

export default observer(function CreateIntroRecommendation(props: Props) {
  const { introRecommendationStore } = useStore();
  const { createRecommendation } = introRecommendationStore;

  const [documentFileName, setDocumentFileName] = useState('');

  const [section, setSection] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [attachmentFile, setAttachmentFile] = useState<File>();

  const [isSectionError, setIsSectionError] = useState<boolean>(false);
  const [isFileError, setIsFileError] = useState<boolean>(false);

  const [uploadConfirmModalOpen, setUploadConfirmModalOpen] = useState<boolean>(false);


  useEffect(() => {
    const fetchData = async () => {
    }
    fetchData()
      .catch(console.error);
  }, [])

  const handleDocumentFile = (e: any) => {

    const file = e.target.files![0];
    if (file && fileExtension(file.name.trim()).toUpperCase() === "PDF") {
      let filename = e.target.files[0].name;
      setAttachmentFile(file);
      setDocumentFileName(filename);
    }

  }



  const fileExtension = (fileName: string) => {
    if (fileName && fileName != '')
      return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    else
      return ''
  }

  function CloseSubmit() {
    props.handleClose(false);
  }


  const handleCancel = () => {
    setUploadConfirmModalOpen(false);
  }




  async function handleFormSubmit() {
    try {
      setLoading(true);
      let errorExist: boolean = false;
      if (section.length <= 0) {
        setIsSectionError(true)
        errorExist = true;
      }

      if (!attachmentFile) {
        setIsFileError(true);
        errorExist = true;
      }

      if (errorExist) {
        return;
      }
      if (attachmentFile) {
        const formData = new FormData();

        formData.append('VehicleType', props.vehicleType.toString());
        formData.append('Section', section);
        formData.append('IntroRecommendationDocument', attachmentFile);

        var result = await createRecommendation(formData).then(() => {
          props.handleClose();
          toast.success('Tavsiye ve Öneri başarıyla eklendi.')
        });
        await introRecommendationStore.loadIntroRecommendations();
      }
    }
    catch (error) {
      //console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>
  
      <div className="modalWrapper">
        <div className='' style={{ width: '100%', padding: '0 20px' }}>

          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Bölüm </div>
          <InputErrorMessage inError={isSectionError} error='Bölüm boş bırakılamaz!' />
          <input type="text" maxLength={100} name="" style={{ margin: '10px 0', width: '100%' }} id="" placeholder='Bir başlık giriniz'
            onChange={(event) => { setSection(event.target.value); setIsSectionError(false); }} value={(section == null) ? '' : section} />
        </div>

        <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column' }}>
          <div style={{ marginLeft: '115px'}}>
          </div>


          <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column' }}>
            <div style={{ marginLeft: '115px', marginBottom:'10px'  }}>
              <InputErrorMessage inError={isFileError} error='Dosya boş bırakılamaz!' />
            </div>
            <label htmlFor="documentInput"
              style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
              className="btn">
              <div className="entryModalButton dark-gray-button">
                Dosya Seç
                <i style={{ margin: '0 5px ' }} className="file icon"></i>
                <input className='button dark-gray-button' id='documentInput'
                  style={{ height: '40px', width: '250px', color: 'white', visibility: 'hidden' }}
                  onChange={(event) => { handleDocumentFile(event); setIsFileError(false); }}
                  accept="application/pdf"
                  type='file' />
              </div>
            </label>
            {documentFileName != '' ?
              <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <div className="selectedBorderBox" >
                  <div className="selectedFileDesc" style={{ padding: '10px' }}>
                    <div className="selectedFileName">Dosya Adı : <b> {documentFileName}</b></div>
                  </div>
                </div>
              </div>
              : <div />}
          </div>
        </div>
        <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '20px ' }}>
          <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Kapat' />
          {/* {documentFileName != '' && imageFileName != ''  ? */}
          <input className='button mr20 confirm-button' onClick={() => { handleFormSubmit(); }} type='submit' value='Yükle' />
          {/* : ''} */}

        </div>
      </div>
    </>


  )

})