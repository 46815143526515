import { useEffect, useState } from "react";
import { Confirm, Dropdown } from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { ActivityDTO, DeleteActivityDTO } from '../../app/models/models';
import LoadingComponent from "../../app/layout/LoadingComponent";
import ModalInnerContainer from "../../app/common/modals/ModalInnerContainer";
import { toast } from 'react-toastify'
import CreateActivity from "./CreateActivity";
import UpdateActivity from "./UpdateActivity";
import ReactDatePicker from "react-datepicker";
import { tr } from 'date-fns/locale';

export default observer(function ActivityIndex() {
    const { activityStore } = useStore();
    const [activities, setActivities] = useState<ActivityDTO[]>([]);
    const [filteredActivities, setFilteredActivities] = useState<ActivityDTO[]>([]);
    const [search, setSearch] = useState('');
    const [regionDropdown, setRegionDropdown] = useState('');
    const [finishDate, setFinishDate] = useState<any>('');
    const [startDate, setStartDate] = useState<any>('');


    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);

    const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = useState<boolean>(false);
    const [createActivityModal, setCreateActivityModal] = useState<boolean>(false);
    const [updateActivityModal, setUpdateActivityModal] = useState<boolean>(false);

    const [elementId, setElementId] = useState<string>('');

    const listPerPage = 5;

    useEffect(() => {
        const fetchData = async () => {

            await activityStore.loadActivities();
            if (activityStore.activities) {
                setActivities(JSON.parse(JSON.stringify(activityStore.activities.activityList)));
                setFilteredActivities(JSON.parse(JSON.stringify(activityStore.activities.activityList)));
                setCurrentPage(1)
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(activityStore.activities.activityList)))).length / listPerPage))
            }
        }
        fetchData()
            .catch(console.error);
    }, [])

    useEffect(() => {
        if (activityStore.activities) {
            setActivities(JSON.parse(JSON.stringify(activityStore.activities.activityList)));
            setFilteredActivities(JSON.parse(JSON.stringify(activityStore.activities.activityList)));
            setCurrentPage(1)
            setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(activityStore.activities.activityList)))).length / listPerPage))
        }
    }, [activityStore.activities])

    const handleFilter = () => {

        var rp = activities.filter((item) => {
            return search?.toLocaleLowerCase('tr-TR') == '' ? item : item.title?.toLocaleLowerCase('tr-TR').includes(search.toLocaleLowerCase('tr-TR'));
        }).filter((item) => {
            let cDate = new Date(parseInt(item.startDate.slice(6, 10)), parseInt(item.startDate.slice(3, 5)) - 1, parseInt(item.startDate.slice(0, 2)), parseInt(item.startDate.slice(11, 13)), parseInt(item.startDate.slice(14, 16)))
            let mDate = new Date(parseInt(item.finishDate.slice(6, 10)), parseInt(item.finishDate.slice(3, 5)) - 1, parseInt(item.finishDate.slice(0, 2)), parseInt(item.finishDate.slice(11, 13)), parseInt(item.finishDate.slice(14, 16)))
            let sDate = startDate == undefined || startDate == '' ? new Date(1970, 0, 0) : new Date(startDate);
            let fDate = finishDate == undefined || finishDate == '' ? new Date(2100, 11, 30) : new Date(finishDate);

            return cDate >= sDate && mDate <= fDate;
        })
        setCurrentPage(1);
        setPageCount(Math.ceil(rp.length / listPerPage))
        setFilteredActivities(rp);
        if (rp.length == 0) {
            toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
        }
    }

    const clearFilters = () => {
        setCurrentPage(1);
        setFilteredActivities(activities);
        setPageCount(Math.ceil(activities.length / listPerPage));
        setSearch('');
        setStartDate('');
        setFinishDate('');
    }

    const closeCreateActivityModal = () => {
        setCreateActivityModal(false);
    }

    const closeUpdateActivityModal = () => {
        setUpdateActivityModal(false);
    }





    function createElements() {
        var elements = [];
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
        elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
        return elements;
    }

    const changePage = async (newPage: number) => {
        setCurrentPage(newPage);
    }

    async function ConfirmRemoveSubmit() {
        try {
            let rid = new DeleteActivityDTO();
            rid.id = elementId;
            var res = await activityStore.delete(rid);
            if (res === 200) {
                setRemoveConfirmModalOpen(false);
                toast.success('Mola yeri başarıyla kaldırıldı.')
                await activityStore.loadActivities();
            }
            else {
                toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ")
            }
        }
        catch (error) {
            //console.log(error);
        }
    }


    const handleCancel = () => {
        setRemoveConfirmModalOpen(false);
    }

    const handleRemove = (id: string) => {
        setElementId(id);
        setRemoveConfirmModalOpen(true);
    }


    const handleUpdate = (id: string) => {
        setElementId(id);
        setUpdateActivityModal(true);

    }

    const handleSetSearch = (result: any) => {
        setSearch(result)
        setCurrentPage(1);
    }

    const handleRegionDropdown = (value: any) => {
        setRegionDropdown(value.toString());
    }



    if (activityStore.loadingInitial) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                <div className='HeaderContainer' style={{ display: 'flex', height: '50px', alignItems: 'center', paddingLeft: '20px', background: '#32C5FF' }}>
                    <span className='BannerTitle'>SAĞLIK BAKIM TIRI</span>
                </div>
                <div className='defaultBodyContainer' style={{ width: '100%' }}>
                    <ModalInnerContainer width={'850px'} body={<CreateActivity handleClose={closeCreateActivityModal} />} modalTitle='Etkinlik Ekle' open={createActivityModal} closeModal={closeCreateActivityModal} />
                    <ModalInnerContainer width={'850px'} body={<UpdateActivity elementId={elementId} handleClose={closeUpdateActivityModal} />} modalTitle='Etkinlik Güncelle' open={updateActivityModal} closeModal={closeUpdateActivityModal} />

                    <Confirm className='RemoveConfirmModal'
                        open={removeConfirmModalOpen}
                        content='Mola yerini kaldırmak istiyor musunuz?'
                        cancelButton='Vazgeç'
                        confirmButton="Tamam"
                        onCancel={handleCancel}
                        onConfirm={() => { ConfirmRemoveSubmit(); }}
                        size='mini'
                    />

                    <div className="" style={{ background: 'white' }}>
                        <div className="searchFilter">
                            <div className="activitySearch" style={{ width: '180px', marginRight: '15px' }} >
                                <p>Etkinlik Ara</p>

                                <div className="ui category search" style={{ width: '180px' }}>
                                    <div className="ui icon input" style={{ width: '180px ' }} >
                                        <input className="prompt" style={{ background: '#F2F2F2' }} type="text" placeholder="Ara" onChange={(event) => handleSetSearch(event.target.value)} value={search}></input>
                                        <i className="search icon"></i>
                                    </div>
                                    <div className="results"></div>
                                </div>
                            </div>
                            <div className="Startdate" style={{ display: 'flex', flexDirection: 'column' }} >
                                <p>Yayınlanma Tarih</p>
                                <div className="sDates" style={{ display: 'flex' }}>
                                    <div className="ui input icon" style={{ marginRight: '10px' }} >
                                        <ReactDatePicker
                                            autoComplete='off'
                                            locale={tr}
                                            dateFormat={'dd.MM.yyyy'}
                                            name='StartDate'
                                            placeholderText='gg.AA.YYYY'
                                            selected={startDate}
                                            onChange={setStartDate}
                                            className="dateArea"
                                        />
                                        <i className="calendar icon" ></i>
                                    </div>

                                </div>
                            </div>
                            <div className="Enddate" style={{ display: 'flex', flexDirection: 'column' }} >
                                <p>Kaldırılma Tarihi</p>
                                <div className="eDates" style={{ display: 'flex' }}>
                                    <div className="ui input icon" style={{ zIndex: '2' }} >
                                        <ReactDatePicker
                                            autoComplete='off'
                                            locale={tr}
                                            dateFormat={'dd.MM.yyyy'}
                                            name='StartDate'
                                            placeholderText='gg.AA.YYYY'
                                            selected={finishDate}
                                            onChange={setFinishDate}
                                            className="dateArea"
                                        />
                                        <i className="calendar icon" ></i>
                                    </div>
                                </div>
                            </div>
                            <div className="filterButtonWrapper" style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                                <input className='button  history-button' onClick={() => { handleFilter() }} type='submit' value='Ara' />
                                <input style={{ marginLeft: '8px' }} className='button  return-button' onClick={() => { clearFilters() }} type='submit' value='Temizle' />
                                <input style={{ marginLeft: '8px' }} className='button  confirm-button' onClick={() => { setCreateActivityModal(true) }} type='submit' value='Ekle' />
                            </div>

                        </div>

                        {filteredActivities.length < 1 ?
                            <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
                                <h1>Girdiğiniz kriterlere ait kayıt bulunamamıştır.</h1>
                                <input style={{ margin: 'auto' }} className='button  close-button' onClick={() => { clearFilters() }} type='submit' value='Geri Dön' />
                            </div>

                            : <div className="VideoCardList" >
                                <table style={{ padding: ' 15px 0 0 0 ' }}>
                                    <thead style={{ background: '#32C5FF' }}>
                                        <tr>
                                            <th>Etkinlikler</th>
                                            <th>Başlangıç Tarihi</th>
                                            <th>Bitiş Tarihi</th>
                                            <th>Güncelle</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredActivities.slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                            .map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.title}</td>
                                                    <td>{item.startDate}</td>
                                                    <td>{item.finishDate}</td>
                                                    <td className="" style={{ padding: '0', width: '60px' }}>
                                                        <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleUpdate((item.id).toString()) }} ><i style={{ margin: '0px 0px 4px 0px', color: 'rgb(200,200,200)' }} className="sync list-icon icon"></i></button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>}
                    </div>
                    {pageCount < 1 ?
                        <div style={{ float: 'right', margin: '0.8rem', height: '40px' }}></div>
                        :
                        <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }} >{createElements()}</div>
                    }
                </div>



            </div >
        </>
    )
})