import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm, Dropdown } from 'semantic-ui-react';
import { toast } from 'react-toastify'
import { useStore } from '../../../app/stores/store';
import InputErrorMessage from '../../components/inputErrorMessage';
import LoadingComponent from '../../../app/layout/LoadingComponent';

interface Props {
  handleClose: any;
  elementId: string;
}

export default observer(function DetailSystemNotification(props: Props) {
  const { systemNotificationStore } = useStore();
  const { updateSystemNotification } = systemNotificationStore;


  const [vehicleTypeDropdown, setVehicleTypeDropdown] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isVehicleTypeDropdownError, setIsVehicleTypeDropdownError] = useState<boolean>(false);
  const [statusCbx, setStatusCbx] = useState<boolean>(false);

  const [notificationType, setNotificationType] = useState('1');
  const [notificationTypeName, setNotificationTypeName] = useState('');

  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [isContentError, setIsContentError] = useState<boolean>(false);
  const [isTitleError, setIsTitleError] = useState<boolean>(false);
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await systemNotificationStore.getSystemNotificationDetail(props.elementId)
      if (data) {
        setContent(data.content);
        setVehicleTypeDropdown(data.vehicleType == "Kamyon" ? "Truck" : "Bus");
        setStatusCbx(data.status == 'A' ? true : false);
        setTitle(data.title);
      }

      setNotificationType('1');
      setNotificationTypeName('Standart Garantisi Biten Araçlar');
    }
    fetchData()
      .catch(console.error);
  }, [])

  function CloseSubmit() {
    props.handleClose(false);
  }

  async function handleFormSubmit() {
    try {
      setLoading(true);
      let errorExist: boolean = false;
      const formData = new FormData();
      if (content.length <= 0) {
        setIsContentError(true)
        errorExist = true;
      }
      if (vehicleTypeDropdown.length <= 0) {
        setIsVehicleTypeDropdownError(true)
        errorExist = true;
      }
      if (title.length <= 0) {
        setIsTitleError(true)
        errorExist = true;
      }
      if (errorExist) {
        return;
      }
      if (true) {
        formData.append('Id', props.elementId);
        formData.append('VehicleType', vehicleTypeDropdown);
        formData.append('Content', content);
        formData.append('Title', title);
        formData.append('Status', statusCbx ? 'A' : 'P');
        formData.append('NotificationType', notificationType);
        formData.append('NotificationTypeName', notificationTypeName);

        var result = await updateSystemNotification(formData).then(() => {
          props.handleClose();
          toast.success('Bildirim başarıyla güncellendi.')
        });
        await systemNotificationStore.loadSystemNotifications();
      }
    }
    catch (error) {
      //console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>

      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="topWrap" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div className="vehicleTypeDropdown " style={{ width: '200px', marginRight: '15px', padding: '0 20px' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem 0', width: '150px' }}>Ürün Grubu</div>
            <InputErrorMessage inError={isVehicleTypeDropdownError} error='Bu alan boş bırakılamaz!' />
            <div className="" style={{ height: '10px' }}>
              <Dropdown style={{
                width: '150px',
                backgroundColor: 'rgb(242,242,242)',
                height: '36px',
                margin: '9px 0',
              }}
                placeholder='Seçiniz'
                fluid
                selection
                disabled
                options={[{ key: 'truck', text: "Kamyon", value: "Truck" },
                { key: 'bus', text: "Otobüs", value: "Bus" },
                ]}
                value={vehicleTypeDropdown}
                onChange={(event, { value }) => {
                  if (value != null) {
                    setVehicleTypeDropdown(value.toString());
                    setIsVehicleTypeDropdownError(false);
                    setIsDataChanged(true);

                  }
                }}
              />

            </div>

          </div>
          <div className="status" style={{ padding: '0 20px' }}>
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem 0', width: '150px' }}>Durum</div>
            <div className="" style={{ display: 'flex', padding: '10px' }}>
              <label className="switch" style={{ margin: '0 15px 0 0' }}>
                <input checked={statusCbx} onChange={(event) => { setStatusCbx(event.target.checked); setIsDataChanged(true); }} name="statusCbx" type="checkbox" />
                <span className="slider round"></span>
              </label>
              <p>{statusCbx ? 'Aktif' : 'Pasif'}</p>
            </div>
          </div>
        </div>

        <div className="inputWrap" style={{ padding: '20px', width: '100%', marginTop: '20px' }}>
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Bölüm</div>
          <div className="sectionDropdown " style={{ padding: '10px 0', width: '100%' }} >
            <Dropdown style={{
              width: '100%',
              backgroundColor: 'rgb(242,242,242)',
              height: '43px',
              margin: '0 auto',
              fontSize: '16px',
            }}
              placeholder='Başlık Seçiniz.'
              fluid
              selection
              options={[{ key: 'all', text: "Standart Garantisi Biten Araçlar", value: "1" },]}
              value={notificationType}
              onChange={(event, { value, text }) => {
                if (value != null) {
                  setNotificationType(value.toString());
                  setNotificationTypeName(text!.toString());
                  setIsDataChanged(true);
                }
              }}
            />
          </div>
        </div>
        <div className="inputWrap" style={{ padding: '0 20px', width: '100%' }}>
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Başlık </div>
          <InputErrorMessage inError={isTitleError} error='Bu alan boş bırakılamaz!' />
          <input type="text" maxLength={100} name="" style={{ margin: '10px 0', width: '100%' }} id=""
            onChange={(event) => { setTitle(event.target.value); setIsTitleError(false); }} value={(title == null) ? '' : title} />

        </div>
        <div className="inputWrap" style={{ padding: '0 20px', width: '100%' }}>
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Metin İçeriği </div>
          <InputErrorMessage inError={isContentError} error='Bu alan boş bırakılamaz!' />
          <textarea name="" style={{ margin: '10px 0', width: '100%', height: '100px', resize: 'none' }} id=""
            onChange={(event) => { setContent(event.target.value); setIsContentError(false); setIsDataChanged(true); }} value={(content == null) ? '' : content} />
        </div>
        <div className="footnote" style={{ padding: '0 20px', width: '100%', marginBottom: '20px' }}>NOT: Metin içeriğininbaşında yer alan parantez işaretini "[ ]" silmeyiniz ve içerisine bilgi girişi yapmayınız</div>
      </div>
      <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px ' }}>
        <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Kapat' />
        <input className='button mr20 confirm-button' onClick={() => { handleFormSubmit(); }} type='submit' value='Güncelle' />
      </div>
    </>


  )

})
