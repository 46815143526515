import { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { toast } from 'react-toastify'
import ReactDatePicker from "react-datepicker";
import { tr } from 'date-fns/locale';
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from '../../../app/stores/store';
import { MarketingDTO } from '../../../app/models/models';
import ModalInnerContainer from "../../../app/common/modals/ModalInnerContainer";
import CreateStory from "./CreateStory";
import DetailStory from "./DetailStory";

export default observer(function StoryIndex() {

    const { storyStore } = useStore();
    const [stories, setStories] = useState<MarketingDTO[]>([]);
    const [filteredStories, setFilteredStories] = useState<MarketingDTO[]>([]);

    const [createStoryModal, setCreateStoryModal] = useState<boolean>(false);
    const [detailStoryModal, setDetailStoryModal] = useState<boolean>(false);
    const [isAlreadyCancelled, setIsAlreardCancelled] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [isThereData, setIsThereData] = useState<boolean>(true);

    const [status, setStatus] = useState('');
    const [productDropdown, setProductDropdown] = useState('');

    const [publishDate, setPublishDate] = useState<any>('');
    const [removeDate, setRemoveDate] = useState<any>('');

    const [elementId, setElementId] = useState<string>('');

    const listPerPage = 5;

    useEffect(() => {
        const fetchData = async () => {
            await storyStore.loadStories();
            if (storyStore.stories) {
                setStories(JSON.parse(JSON.stringify(storyStore.stories)));
                setFilteredStories(JSON.parse(JSON.stringify(storyStore.stories)));
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(storyStore.stories)))).length / listPerPage))
            }

            setCurrentPage(1)
        }
        fetchData()
            .catch(console.error);
    }, [])

    useEffect(() => {
            if (storyStore.stories) {
                setStories(JSON.parse(JSON.stringify(storyStore.stories)));
                setFilteredStories(JSON.parse(JSON.stringify(storyStore.stories)));
                setPageCount(Math.ceil(JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(storyStore.stories)))).length / listPerPage))
            }

            setCurrentPage(1)
    }, [storyStore.stories])






    const handleFilter = () => {
        setCurrentPage(1);
        let dts = stories.filter((item) => {
            return status == '' || status == 'all' ? item : item.status.toLowerCase() == status.toLowerCase();
        }).filter((item) => {
            return productDropdown == '' ? item : item.vehicleType.toLowerCase() == productDropdown.toLowerCase();

        }).filter((item) => {
            let cDate = new Date(parseInt(item.startDate.slice(6, 10)), parseInt(item.startDate.slice(3, 5)) - 1, parseInt(item.startDate.slice(0, 2)), parseInt(item.startDate.slice(11, 13)), parseInt(item.startDate.slice(14, 16)))
            let mDate = new Date(parseInt(item.finishDate.slice(6, 10)), parseInt(item.finishDate.slice(3, 5)) - 1, parseInt(item.finishDate.slice(0, 2)), parseInt(item.finishDate.slice(11, 13)), parseInt(item.finishDate.slice(14, 16)))
            let pDate = publishDate == undefined || publishDate == '' ? new Date(1970, 0, 0) : new Date(publishDate);
            let rDate = removeDate == undefined || removeDate == '' ? new Date(2100, 11, 30) : new Date(removeDate);

            return cDate >= pDate && mDate <= rDate;
        })
        setFilteredStories(dts);
        setCurrentPage(1);
        setPageCount(Math.ceil(dts.length / listPerPage))
        if (dts.length == 0) {
            toast.error("Girdiğiniz kriterlere ait kayıt bulunamamıştır.");
            setIsThereData(false)
        }

    }

    const clearFilters = () => {
        handleProductDropdown('');
        handleStatusDropdown('');
        setRemoveDate('');
        setPublishDate('');
        setCurrentPage(1);
        setIsThereData(true);
        setFilteredStories(stories);
    }


    const handleProductDropdown = (value: any) => {
        setProductDropdown(value.toString());
    }

    const handleStatusDropdown = (value: any) => {
        setStatus(value.toString());
    }





    function createElements() {
        var elements = [];
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == 1 ? 'none' : 'block' }} className="icon item" key={0} onClick={() => changePage(currentPage - 1)}><i className="left chevron icon"></i></a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 2 ? 'none' : 'block' }} className="item" key={1} onClick={() => changePage(currentPage - 2)}>{currentPage - 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage <= 1 ? 'none' : 'block' }} className="item" key={2} onClick={() => changePage(currentPage - 1)}>{currentPage - 1}</a>);
        elements.push(<a style={{ cursor: 'pointer' }} className="item active" key={3} onClick={() => changePage(currentPage)}>{currentPage}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 1 > pageCount ? 'none' : 'block' }} className="item" key={4} onClick={() => changePage(currentPage + 1)}>{currentPage + 1}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage + 2 > pageCount ? 'none' : 'block' }} className="item" key={5} onClick={() => changePage(currentPage + 2)}>{currentPage + 2}</a>);
        elements.push(<a style={{ cursor: 'pointer', display: currentPage == pageCount ? 'none' : 'block' }} className="icon item" key={6} onClick={() => changePage(currentPage + 1)}><i className="right chevron icon"></i></a>);
        return elements;
    }

    const changePage = async (newPage: number) => {
        setCurrentPage(newPage);
    }




    const closeCreateStoryModal = () => {
        setCreateStoryModal(false);
    }
    const closeDetailStoryModal = () => {
        setDetailStoryModal(false);
    }

     if (storyStore.loadingInitial) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

    const handleDetail = (id: string, cancelled: boolean) => {
        setElementId(id);
        setIsAlreardCancelled(cancelled)
        setDetailStoryModal(true);
    }
    return (
        <>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>

                <div className='ApproverBodyContainer' style={{ width: '100%' }}>
                    <ModalInnerContainer width={'600px'} body={<CreateStory handleClose={closeCreateStoryModal} />} modalTitle='Story' open={createStoryModal} closeModal={closeCreateStoryModal} />
                    <ModalInnerContainer width={'600px'} body={<DetailStory handleClose={closeDetailStoryModal} elementId={elementId} isAlreadyCancelled={isAlreadyCancelled} />} modalTitle='Story' open={detailStoryModal} closeModal={closeDetailStoryModal} />

                    <div className="" style={{ background: 'white' }}>
                        <div className="searchFilter">

                            <div className="productDropdown " style={{ width: '180px', marginRight: '15px' }} >
                                <p>Ürün Grubu</p>
                                <Dropdown style={{
                                    width: '180px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Seçiniz'
                                    fluid
                                    selection
                                    options={[{ key: 'truck', text: "Kamyon", value: "Kamyon" },
                                    { key: 'bus', text: "Otobüs", value: "Otobüs" },
                                    ]}
                                    value={productDropdown}
                                    onChange={(event, { value }) => {
                                        if (value != null) {
                                            setProductDropdown(value.toString());
                                        }
                                    }}
                                />
                            </div>
                            <div className="statusDropdown " style={{ width: '200px', marginRight: '15px' }} >
                                <p>Durum</p>
                                <Dropdown style={{
                                    width: '200px',
                                    backgroundColor: 'rgb(242,242,242)',
                                    height: '43px',
                                    margin: '0 auto',
                                    fontSize: '16px',
                                }}
                                    placeholder='Story Statüsü'
                                    fluid
                                    selection
                                    options={[
                                        { key: '0', text: "Tümü", value: "all" },
                                        { key: '1', text: "Yayın tarihi gelmedi", value: "Yayın tarihi gelmedi" },
                                        { key: '2', text: "Yayında", value: "Yayında" },
                                        { key: '3', text: "Süresi doldu", value: "Süresi doldu" },
                                        { key: '4', text: "Yayından kaldırıldı", value: "Yayından kaldırıldı" },
                                    ]}
                                    value={status}
                                    onChange={(event, { value }) => {
                                        if (value != null) {
                                            setStatus(value.toString());
                                        }
                                    }}
                                />

                            </div>

                            <div className="Startdate" style={{ display: 'flex', flexDirection: 'column' }} >
                                <p>Yayınlanma Tarih</p>
                                <div className="sDates" style={{ display: 'flex' }}>
                                    <div className="ui input icon" style={{ marginRight: '10px' }} >
                                        <ReactDatePicker
                                            autoComplete='off'
                                            locale={tr}
                                            dateFormat={'dd.MM.yyyy'}
                                            name='StartDate'
                                            placeholderText='gg.AA.YYYY'
                                            selected={publishDate}
                                            onChange={setPublishDate}
                                            className="dateArea"
                                        />
                                        <i className="calendar icon" ></i>
                                    </div>

                                </div>
                            </div>
                            <div className="Enddate" style={{ display: 'flex', flexDirection: 'column' }} >
                                <p>Kaldırılma Tarihi</p>
                                <div className="eDates" style={{ display: 'flex' }}>
                                    <div className="ui input icon" style={{ zIndex: '2' }} >
                                        <ReactDatePicker
                                            autoComplete='off'
                                            locale={tr}
                                            dateFormat={'dd.MM.yyyy'}
                                            name='StartDate'
                                            placeholderText='gg.AA.YYYY'
                                            selected={removeDate}
                                            onChange={setRemoveDate}
                                            className="dateArea"
                                        />
                                        <i className="calendar icon" ></i>
                                    </div>
                                </div>
                            </div>
                            <div className="filterButtonWrapper" style={{ display: 'flex', marginTop: '32px', alignItems: 'center' }} >
                                <input className='button history-button' onClick={() => { handleFilter() }} type='submit' value='Ara' />
                                <input style={{ marginLeft: '8px' }} className='button  return-button' onClick={() => { clearFilters() }} type='submit' value='Temizle' />
                                <input style={{ marginLeft: '8px' }} className='button   confirm-button' onClick={() => { setCreateStoryModal(true); }} type='submit' value='Ekle' />
                            </div>

                        </div>

                        {!isThereData ?
                            <div style={{ background: 'white', textAlign: 'center', padding: '150px 0' }}>
                                <h1>Girdiğiniz kriterlere ait kayıt bulunamamıştır.</h1>
                                <input style={{ margin: 'auto' }} className='button  close-button' onClick={() => { clearFilters() }} type='submit' value='Geri Dön' />

                            </div>
                            :

                            <div className="dataTable">
                                <table style={{ padding: ' 15px 0 0 0 ' }}>
                                    <thead style={{ background: '#32C5FF' }}>
                                        <tr>

                                        </tr>
                                        <tr>
                                            <th rowSpan={2}>Ürün Grubu</th>
                                            <th rowSpan={2}>Başlık</th>
                                            <th rowSpan={2}>Story Yayınlanma Tarihi</th>
                                            <th rowSpan={2}>Story Kaldırma Tarihi</th>
                                            <th rowSpan={2}>Durum</th>
                                            <th colSpan={4}>Görüntülenme Sayıları
                                            </th>
                                            <th rowSpan={2}>Detay</th>
                                        </tr>
                                        <tr >
                                            <th>KA</th>
                                            <th>NKA</th>
                                            <th>Driver</th>
                                            <th>SSH</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        {filteredStories.slice((currentPage - 1) * listPerPage, currentPage * listPerPage)
                                            .map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.vehicleType}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.startDate}</td>
                                                    <td>{item.finishDate}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.kaClickCount}</td>
                                                    <td>{item.nkaClickCount}</td>
                                                    <td>{item.driverClickCount}</td>
                                                    <td>{item.sshClickCount}</td>
                                                    <td className="" style={{ padding: '0', width: '60px' }}>
                                                        <button className='button tableButton' style={{ margin: 'auto', background: 'inherit' }} onClick={() => { handleDetail((item.id).toString(), item.status == 'Yayından kaldırıldı' || item.status == 'Süresi doldu' ? true : false) }} ><i style={{margin: '0px 0px 4px 0px', color: 'rgb(70,170,250)' }} className="info list-icon icon"></i></button>
                                                        {/* <input className='button  confirm-button' style={{ margin: 'auto', boxShadow: 'none' }} onClick={() => { handleFilter() }} type='submit' value='Güncelle' /> */}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        }


                    </div>
                    {pageCount < 1 ?
                        <div style={{ float: 'right', margin: '0.8rem', height: '40px' }}></div>
                        :
                        <div className="ui pagination menu" style={{ float: 'right', margin: '0.8rem' }} >{createElements()}</div>
                    }
                </div>
            </div >
        </>
    )
})