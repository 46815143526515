import { makeAutoObservable } from "mobx";
import { CancelMarketingDTO, DeleteRestingPlaceDTO, DetailMarketingDTO, MarketingDTO, RestingPlaceDTO, RestingPlaceListDTO } from "../models/models";
import agent from "../api/agent";

export default class CampaignStore {

    campaigns: MarketingDTO | undefined = undefined;

    loadingInitial = false;
    modalLoadingInitial = false;

    constructor() {
        makeAutoObservable(this);
    }
    setLoadingInitial = (val: boolean) => {
        this.loadingInitial = val;
    }
    setModalLoadingInitial = (val: boolean) => {
        this.modalLoadingInitial = val;
    }

    loadCampaigns = async () => {
        this.setModalLoadingInitial(true);
        try {
            const result = await agent.Campaign.list();
            if (result) {
                this.campaigns = JSON.parse(JSON.stringify(result));
                return true
            }
            else
                return false;
        } catch (error) {
            //console.log(error);
            return false;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    delete = async (req: DeleteRestingPlaceDTO) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.RestingPlaces.delete(req);
            return res;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }
    getCampaignDetail = async (id: string): Promise<DetailMarketingDTO | null> => {
        this.setModalLoadingInitial(true);
        try {
            const result = await agent.Campaign.detail(id);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }
    cancelCampaign = async (campaign: CancelMarketingDTO) => {
        this.setModalLoadingInitial(true);
        try {
            var res = await agent.Campaign.cancelCampaign(campaign);
            return res;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }

    excelExport = async (postUrl: string, postId: string, filename: string) => {
        this.setModalLoadingInitial(true);
        try {
            const result = await agent.Campaign.excelExport(postUrl, postId, filename);
            return result;
        } catch (error) {
            //console.log(error);
            return null;
        }
        finally {
            this.setModalLoadingInitial(false);
        }
    }


    createCampaign = async (campaign: FormData) => {
        try {
            return await agent.Campaign.createCampaign(campaign);
        } catch (error) {
            //console.log(error);
            return false;
        }
    }

    updateRestingPlace = async (restingPlace: FormData) => {
        try {
            return await agent.RestingPlaces.update(restingPlace);
        } catch (error) {
            //console.log(error);
            return false;
        }

    }
}