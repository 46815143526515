import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import NotFound from '../../features/errors/NotFound';
import ServerError from '../../features/errors/ServerError';
import LoginForm from '../../features/users/LoginForm';
import { useStore } from '../stores/store';
import LoadingComponent from './LoadingComponent';
import ModalContainer from '../common/modals/ModalContainer';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Dashboard from '../../features/dashboard/Dashboard';
import IntrosIndex from '../../features/intro/IntrosIndex';
import RestingPlacesIndex from '../../features/restingPlaces/RestingPlacesIndex';
import MarketingDashboard from '../../features/marketing/MarketingDashboard';
import NotificationsDashboard from '../../features/notification/NotificationsDashboard';
import HealthCareIndex from '../../features/activity/ActivityIndex';


function App() {
  const { commonStore, userStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore])

  if (!commonStore.appLoaded) return <LoadingComponent content='Uygulama yükleniyor...' />

  return (
    <div style={{ height: '100%' }}>
      <ToastContainer position='top-center' hideProgressBar />
      <ModalContainer />
      <PublicRoute exact path='/' component={LoginForm} />
      <Route
        path={'/(.+)'}
        render={() => (
          <>
            <Switch>
              <PrivateRoute exact path='/dashboard' component={Dashboard} />
              <PrivateRoute exact path='/intros' component={IntrosIndex} />
              <PrivateRoute exact path='/restingPlaces' component={RestingPlacesIndex} />
              <PrivateRoute exact path='/marketing' component={MarketingDashboard} />
              <PrivateRoute exact path='/notifications' component={NotificationsDashboard} />
              <PrivateRoute exact path='/healthCare' component={HealthCareIndex} />
              <Route path='/server-error' component={ServerError} />
              <PublicRoute path='/login' component={LoginForm} />
              
              <Route component={NotFound} />
            </Switch>
          </>
        )}
      />
    </div>
  );
}

export default observer(App);
