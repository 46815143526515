import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm, Dropdown } from 'semantic-ui-react';
import { toast } from 'react-toastify'
import { useStore } from '../../../app/stores/store';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { history } from "../../..";
import ReactDatePicker from 'react-datepicker';
import { tr } from 'date-fns/locale';
import { CancelMarketingDTO } from '../../../app/models/models';

interface Props {
  handleClose: any;
  elementId: string;
  isAlreadyCancelled: boolean
  dataType: string;
}

export default observer(function DetailCompanyNew(props: Props) {
  const { companyNewStore, campaignStore } = useStore();

  const [startDate, setStartDate] = useState<any>('');
  const [finishDate, setFinishDate] = useState<any>('');
  const [title, setTitle] = useState('');
  const [pdfFileName, setPDFFileName] = useState('');
  const [imageFileName, setImageFileName] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedPDF, setSelectedPDF] = useState('');
  const [productDropdown, setProductDropdown] = useState('');

  const [vehicleType, setVehicleType] = useState(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState('');

  const [cancelConfirmModalOpen, setCancelConfirmModalOpen] = useState<boolean>(false);
  const [elementId, setElementId] = useState<string>('');


  const [loading, setLoading] = useState<boolean>(false);

  const [uploadConfirmModalOpen, setUploadConfirmModalOpen] = useState<boolean>(false);
  const [isCompanyNew, setIsCompanyNew] = useState<boolean>(false);

  const [description, setDescription] = useState('');
  const [isDescriptionError, setIsDescriptionError] = useState<boolean>(false);
  const [formUrl, setFormUrl] = useState('');
  const [thumbnailFileName, setThumbnailFileName] = useState('');



  useEffect(() => {
    const fetchData = async () => {
      let data = null
      if (props.dataType == 'Haber') {
        data = await companyNewStore.getCompanyNewDetail(props.elementId);
      }
      else {
        data = await campaignStore.getCampaignDetail(props.elementId);
        setIsCompanyNew(data!.isCompanyNew)
      }

      if (data) {
        setTitle(data.title);
        setProductDropdown(data.vehicleType == 'Bus' ? 'Otobüs' : 'Kamyon');
        let sdate = new Date(data.startDate)
        let fdate = new Date(data.finishDate)

        if (data.image && fileExtension(data.image.trim()).toUpperCase() === "PDF") {
          setPDFFileName(data.image);
          setSelectedPDF(data.image);
          setSelectedThumbnail(data.thumbnail);
          setThumbnailFileName(data.thumbnail);
        }
        else {
          setSelectedImage(data.image);
          setImageFileName(data.image);
        }

        setVehicleType(data.vehicleType == 'Bus' ? true : false);
        setFinishDate(fdate);
        setStartDate(sdate);
        setFormUrl(data.formUrl);
        setDescription(data.description);
      }

    }
    fetchData()
      .catch(console.error);
  }, [])

  function CloseSubmit() {
    props.handleClose(false);
  }



  const handleCancel = () => {
    setUploadConfirmModalOpen(false);
  }






  const fileExtension = (fileName: string) => {
    if (fileName && fileName != '')
      return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    else
      return ''
  }


  const handleCancelModal = (id: string) => {
    setElementId(id);
    setCancelConfirmModalOpen(true);
  }

  async function ConfirmCancelSubmit() {
    try {
      let rid = new CancelMarketingDTO();
      rid.id = elementId;
      var res = await companyNewStore.cancelCompanyNew(rid);
      if (res === 200) {
        setCancelConfirmModalOpen(false);
        props.handleClose();
        toast.success('Haber başarıyla yayından kaldırıldı.')
        await companyNewStore.loadCompanyNews();
      }
      else {
        toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ")
      }
    }
    catch (error) {
      //console.log(error);
    }
  }

  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>

      <Confirm className='RemoveConfirmModal'
        open={cancelConfirmModalOpen}
        content="Haberi iptal etmek istiyor musunuz?"
        cancelButton='Vazgeç'
        confirmButton="Tamam"
        onCancel={handleCancel}
        onConfirm={() => { ConfirmCancelSubmit(); }}
        size='mini'
      />
      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="topWrap" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="inputWrap" style={{ padding: '0 20px', width: '80%' }}>
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Haber Başlık </div>
            <input disabled type="text" name="" style={{ margin: '10px 0', width: '100%' }} id=""
              onChange={(event) => { setTitle(event.target.value); }} value={(title == null) ? '' : title} />
          </div>
          <div className="productDropdown " style={{ width: '180px', marginRight: '15px' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0', }}>Ürün Grubu</div>
            <div className="" style={{ height: '10px' }}>
              <Dropdown style={{
                width: '180px',
                backgroundColor: 'rgb(242,242,242)',
                height: '36px',
                margin: '9px 0',
              }}
                placeholder='Seçiniz'
                fluid
                selection
                disabled
                options={[{ key: 'truck', text: "Kamyon", value: "Kamyon" },
                { key: 'bus', text: "Otobüs", value: "Otobüs" },
                ]}
                value={productDropdown}
                onChange={(event, { value }) => {
                  if (value != null) {
                    setProductDropdown(value.toString());
                  }
                }}
              />

            </div>

          </div>
        </div>
        <div className="inputWrap" style={{ padding: '0 20px', width: '100%' }}>
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Haber Açıklama </div>
          <textarea disabled name="" style={{ margin: '10px 0', width: '100%', height: '72px', resize: 'none' }} id=""
            onChange={(event) => { setDescription(event.target.value); setIsDescriptionError(false); }} value={(description == null) ? '' : description} />
        </div>

        <div className="dates" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="Startdate" style={{ display: 'flex', flexDirection: 'column', margin: '0  20px', width: '45%' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Yayınlanma Tarihi</div>
            <div style={{ marginBottom: '0px' }}>
            </div>
            <div className="sDates" style={{ display: 'flex', margin: '10px 0' }}>
              <div className="ui input icon" style={{ width: '100%' }} >
                <ReactDatePicker
                  disabled
                  locale={tr}
                  timeInputLabel="Time:"
                  dateFormat="dd/MM/yyyy HH:mm"
                  timeFormat='p'
                  showTimeInput
                  name='FinishDate'
                  placeholderText='gg.AA.YYYY SS:dd'
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                  className="dateAreaStory"
                />
                <i className="calendar icon" ></i>
              </div>

            </div>
          </div>
          <div className="Enddate" style={{ display: 'flex', flexDirection: 'column', margin: '0  20px', width: '45%' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Kaldırılma Tarihi</div>
            <div style={{ marginBottom: '0px' }}>
            </div>
            <div className="eDates" style={{ display: 'flex', margin: '10px 0' }}>
              <div className="ui input icon" style={{ width: '100%' }} >
                <ReactDatePicker
                  disabled
                  locale={tr}
                  timeInputLabel="Time:"
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeInput
                  name='StartDate'
                  timeFormat="HH:mm"
                  placeholderText='gg.AA.YYYY SS:dd'
                  selected={finishDate}
                  onChange={(date) => {
                    setFinishDate(date);
                  }}
                  className="dateAreaStory"
                />
                <i className="calendar icon" ></i>
              </div>
            </div>
          </div>

        </div>

        <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', margin: '10px 0', flexDirection: 'column' }}>
          <div style={{ marginLeft: '175px' }}>
          </div>
          <label htmlFor="picInput"
            style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto', pointerEvents: 'none' }}
            className="btn">
            <div className="entryModalButton dark-gray-button">
              Yüklenen Dosya
              <i style={{ margin: '0 5px ' }} className="file icon"></i>
              <input className='button dark-gray-button'
                id='picInput'
                style={{ height: '40px', width: '250px', color: 'white', visibility: 'hidden' }}
                type='file'
                accept="image/*,application/pdf"
              />
            </div>
          </label>

          {selectedImage ?
            <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column', marginLeft: '50px' }}>
              <div className="selectedBorderBox" style={{ display: 'flex', justifyContent: 'center', padding: '0px 20px 5px 20px ', flexDirection: 'column' }}>
                <div className="selectedFileDesc" style={{ padding: '10px' }}>
                  <div className="selectedFileName" >Dosya Adı : <b>
                    {imageFileName.length > 19 ? imageFileName.substring(0, 10) + '...' + imageFileName.substring(imageFileName.length - 5) : imageFileName}</b></div>
                </div>
                <img alt="preview image" style={{ maxHeight: '220px' }} src={selectedImage} />
                <a href={imageFileName} className="selectedFileName" style={{ textAlign: 'center', padding: '5px' }}> Resmi İndir</a>

              </div>

            </div>
            : <div />}
          {selectedPDF != '' ?

            <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column', marginLeft: '50px' }}>
              <div className="selectedBorderBox" >
                <div className="selectedFileDesc" style={{ padding: '10px' }}>
                  <div className="selectedFileName">Dosya Adı : <b>
                    {pdfFileName.length > 19 ? pdfFileName.substring(0, 10) + '...' + pdfFileName.substring(pdfFileName.length - 5) : pdfFileName}</b></div>


                </div>



              </div>
            </div>
            : <div />}

          {selectedPDF != '' &&
            <div className="thumbWrapper" style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', margin: '10px 0', flexDirection: 'column' }} >
              <div style={{ marginLeft: '170px', marginBottom: '10px' }}>
              </div>
              <label htmlFor="thumbInput"
                style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
                className="btn">
                <div className="entryModalButton dark-gray-button">
                  Thumbnail Ekle
                  <i style={{ margin: '0 5px ' }} className="picture icon"></i>
                  <input className='button dark-gray-button'
                    id='thumbInput'
                    style={{ height: '40px', width: '250px', color: 'white', visibility: 'hidden' }}
                    type='file'
                    accept="image/*"
                  />
                </div>
              </label>
              {selectedThumbnail ?
                <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <div className="selectedBorderBox" style={{ display: 'flex', justifyContent: 'center', padding: '0px 20px 5px 20px ', flexDirection: 'column', marginLeft: '105px' }}>
                    <div className="selectedFileDesc" style={{ padding: '10px' }}>
                      <div className="selectedFileName" >Dosya Adı : <b>
                        {thumbnailFileName.length > 19 ? thumbnailFileName.substring(0, 10) + '...' + thumbnailFileName.substring(thumbnailFileName.length - 5) : thumbnailFileName}</b></div>

                    </div>
                    <img alt="preview image" style={{ maxHeight: '220px' }} src={selectedThumbnail} />
                    <a href={thumbnailFileName} className="selectedFileName" style={{ textAlign: 'center', padding: '5px' }}> Resmi İndir</a>

                  </div>

                </div>
                : <div />}
            </div>
          }


        </div>




      </div>
      <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px ', float: 'right' }}>
        <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Vazgeç' />
        {!props.isAlreadyCancelled &&
          <input className='button mr20 reject-button' onClick={() => { handleCancelModal(props.elementId); }} type='submit' value='Yayından Kaldır' />

        }
      </div>
    </>


  )

})