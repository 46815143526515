import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm, Dropdown } from 'semantic-ui-react';
import { toast } from 'react-toastify'
import { useStore } from '../../../app/stores/store';
import InputErrorMessage from '../../components/inputErrorMessage';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { history } from "../../..";
import ReactDatePicker from 'react-datepicker';
import { tr } from 'date-fns/locale';
interface Props {
  handleClose: any;
}

export default observer(function CreateNotification(props: Props) {
  const { notificationStore } = useStore();
  const { createNotification } = notificationStore;

  const [title, setTitle] = useState('');
  const [isNameError, setIsNameError] = useState<boolean>(false);

  const [uploadConfirmModalOpen, setUploadConfirmModalOpen] = useState<boolean>(false);
  const [vehicleDropdown, setVehicleDropdown] = useState('');
  const [profileDropdown, setProfileDropdown] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isVehicleDropdownError, setIsVehicleDropdownError] = useState<boolean>(false);
  const [isProfileDropdownError, setIsProfileDropdownError] = useState<boolean>(false);
  const [isUsersError, setIsUsersError] = useState<boolean>(false);

  const [content, setContent] = useState('');
  const [users, setUsers] = useState('');
  const [isContentError, setIsContentError] = useState<boolean>(false);
  const [isSendDateError, setIsSendDateError] = useState<boolean>(false);
  const [isUserTypeError, setIsUserTypeError] = useState<boolean>(false);

  const [ownersCbx, setOwnersCbx] = useState(false);
  const [serviceManagersCbx, setServiceManagersCbx] = useState(false);
  const [driversCbx, setDriversCbx] = useState(false);
  const [sendDate, setSendDate] = useState<any>('');

  useEffect(() => {
    const fetchData = async () => {
      setProfileDropdown("A");

    }
    fetchData()
      .catch(console.error);
  }, [])

  function CloseSubmit() {
    props.handleClose(false);
  }




  async function handleDate(date: any) {
    const newDate = new Date(date);
    let res = new Date(newDate.getTime() + (1000 * 60 * 60 * 3));
    return res;
  }

  async function handleFormSubmit() {
    try {
      setLoading(true);
      let errorExist: boolean = false;
      const formData = new FormData();

      if (title.length <= 0) {
        setIsNameError(true)
        errorExist = true;
      }
      if (content.length <= 0) {
        setIsContentError(true)
        errorExist = true;

      }
      if (users.length <= 0 && profileDropdown == 'B') {
        setIsUsersError(true)
        errorExist = true;
      }
      if (vehicleDropdown.length <= 0) {
        setIsVehicleDropdownError(true)
        errorExist = true;
      }
      if (profileDropdown.length <= 0) {
        setIsProfileDropdownError(true)
        errorExist = true;
      }
      if (profileDropdown == 'T' && !driversCbx && !ownersCbx && !serviceManagersCbx) {
        setIsUserTypeError(true)
        errorExist = true;
      }
      if (sendDate.length <= 0) {
        setIsSendDateError(true)
        errorExist = true;
      }


      if (errorExist) {
        return;
      }

      if (true) {
        let sdate = (await handleDate(sendDate)).toISOString()
        formData.append('VehicleType', vehicleDropdown);
        formData.append('Title', title);
        formData.append('Content', content);
        formData.append('DateToSend', sdate);
        formData.append('Profile', profileDropdown);
        formData.append('NotificationUserList', users);
        formData.append('Owners', JSON.stringify(ownersCbx));
        formData.append('ServiceManagers', JSON.stringify(serviceManagersCbx));
        formData.append('Drivers', JSON.stringify(driversCbx));

        var result = await createNotification(formData);
        props.handleClose();

        if (result != false) {
          toast.success('Bildirim başarıyla eklendi.')

        }
        await notificationStore.loadNotifications();
      }
    }
    catch (error) {
      //console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>

      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="topWrap" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="inputWrap" style={{ padding: '0 20px', width: '80%' }}>
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Başlık </div>
            <InputErrorMessage inError={isNameError} error='Bu alan boş bırakılamaz!' />
            <input type="text" maxLength={100} name="" style={{ margin: '10px 0', width: '100%' }} id=""
              onChange={(event) => { setTitle(event.target.value); setIsNameError(false); }} value={(title == null) ? '' : title} />
          </div>
          <div className="vehicleDropdown " style={{ width: '180px', marginRight: '15px' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0', }}>Ürün Grubu</div>
            <InputErrorMessage inError={isVehicleDropdownError} error='Bu alan boş bırakılamaz!' />
            <div className="" style={{ height: '10px' }}>
              <Dropdown style={{
                width: '180px',
                backgroundColor: 'rgb(242,242,242)',
                height: '36px',
                margin: '9px 0',
              }}
                placeholder='Seçiniz'
                fluid
                selection

                options={[{ key: 'truck', text: "Kamyon", value: "Truck" },
                { key: 'bus', text: "Otobüs", value: "Bus" },
                ]}
                value={vehicleDropdown}
                onChange={(event, { value }) => {
                  if (value != null) {
                    setVehicleDropdown(value.toString());
                    setIsVehicleDropdownError(false);
                  }
                }}
              />

            </div>

          </div>
        </div>

        <div className="inputWrap" style={{ padding: '0 20px', width: '100%' }}>
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Metin İçeriği </div>
          <InputErrorMessage inError={isContentError} error='Bu alan boş bırakılamaz!' />
          <textarea name="" style={{ margin: '10px 0', width: '100%', height: '72px', resize: 'none' }} id=""
            onChange={(event) => { setContent(event.target.value); setIsContentError(false); }} value={(content == null) ? '' : content} />
        </div>
        <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', margin: '10px 0', flexDirection: 'column', alignItems: 'center' }}>
          <div className="profileDropdown " style={{ width: '180px', marginRight: '15px' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0', }}>Profil</div>
            <InputErrorMessage inError={isProfileDropdownError} error='Bu alan boş bırakılamaz!' />
            <div className="" style={{ height: '10px' }}>
              <Dropdown style={{
                width: '180px',
                backgroundColor: 'rgb(242,242,242)',
                height: '36px',
                margin: '9px 0',
              }}
                placeholder='Seçiniz'
                fluid
                selection
                options={[{ key: 'all', text: "Tüm Kullanıcılar", value: "A" },
                { key: 'determined', text: "Belirli Kullanıcılar", value: "D" },
                ]}
                value={profileDropdown}
                onChange={(event, { value }) => {
                  if (value != null) {
                    setProfileDropdown(value.toString());
                    setIsProfileDropdownError(false);
                    setDriversCbx(false);
                    setOwnersCbx(false);
                    setServiceManagersCbx(false);
                    setUsers('');
                  }
                }}
              />
            </div>
          </div>
          <div className="" style={{ marginTop: '50px' }}></div>
          {profileDropdown == 'A' ?
            <div className="table">
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Kullanıcı Tipi </div>
              <InputErrorMessage inError={isUserTypeError} error='En az bir seçim yapmalısınız' />
              <table style={{ padding: "10px 0" }} className="serviceTable">
                <tbody>
                  <tr>
                    <td>Araç Sahipleri</td>
                    <td>
                      <label className="switch">
                        <input checked={ownersCbx} onChange={(event) => { setOwnersCbx(event.target.checked); !ownersCbx && setIsUserTypeError(false); }} name="ownersCbx" type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Servis Süreçleri Yetkilileri</td>
                    <td>
                      <label className="switch">
                        <input checked={serviceManagersCbx} onChange={(event) => { setServiceManagersCbx(event.target.checked); !serviceManagersCbx && setIsUserTypeError(false); }} name="serviceManagersCbx" type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Sürücüler</td>
                    <td>
                      <label className="switch">
                        <input checked={driversCbx} onChange={(event) => { setDriversCbx(event.target.checked); !driversCbx && setIsUserTypeError(false); }} name="driversCbx" type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            : profileDropdown == 'D' &&
            <div className="inputWrap" style={{ padding: '0 20px', width: '100%' }}>
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Bildirim Gönderilecek Kullanıcıların Cep Telefonları</div>
              <InputErrorMessage inError={isUsersError} error='Bu alan boş bırakılamaz!' />
              <textarea name="" style={{ margin: '10px 0', width: '100%', height: '72px', resize: 'none' }} id=""
                onChange={(event) => { setUsers(event.target.value); setIsUsersError(false); }} value={(users == null) ? '' : users} />
            </div>
          }
        </div>
      </div>
      <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px ' }}>
        <div className="Senddate" style={{ display: 'flex', flexDirection: 'column', margin: '0  20px', width: '45%' }} >
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Yayınlanma Tarihi</div>
          <div style={{ marginBottom: '0px' }}>
            <InputErrorMessage inError={isSendDateError} error='Bu alan boş bırakılamaz!' />
          </div>
          <div className="sDate" style={{ display: 'flex', margin: '10px 0' }}>
            <div className="ui input icon" style={{ width: '100%' }} >
              <ReactDatePicker
                locale={tr}
                timeInputLabel="Time:"
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat='p'
                showTimeInput
                name='SendDate'
                placeholderText='gg.AA.YYYY SS:dd'
                selected={sendDate}
                onChange={(date) => {
                  setSendDate(date);
                  setIsSendDateError(false);
                }}
                className="dateAreaStory"
              />
              <i className="calendar icon" ></i>
            </div>

          </div>
        </div>
        <div className="" style={{ display: 'flex', height: '36px', marginTop: '34px', float: 'right' }}>

          <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Kapat' />
          <input className='button mr20 confirm-button' onClick={() => { handleFormSubmit(); }} type='submit' value='Ekle' />

        </div>
      </div>
    </>


  )

})
