import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify'
import { history } from "../..";
import InputErrorMessage from '../components/inputErrorMessage';
import LoadingComponent from '../../app/layout/LoadingComponent';

interface Props {
  handleClose: any;
  elementId: string;
  vehicleType: string;
}

export default observer(function CreateIntroCard(props: Props) {
  const { introCardStore } = useStore();
  const { createCard } = introCardStore;

  const [cabinChecbox, setCabinChecbox] = useState<boolean>(false);
  const [driveCehckbox, setDriveCehckbox] = useState<boolean>(false);
  const [chassisCheckBox, setChassisCheckBox] = useState<boolean>(false);
  const [otherCehckbox, setOtherCehckbox] = useState<boolean>(false);
  const [documentFileName, setDocumentFileName] = useState('');
  const [imageFileName, setImageFileName] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  const [title, setTitle] = useState('');
  const [image, setImage] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const [description, setDescription] = useState('');
  const [attachmentFile, setAttachmentFile] = useState<File>();

  const [isTitleError, setIsTitleError] = useState<boolean>(false);
  const [isCheckboxEror, setIsCheckboxEror] = useState<boolean>(false);
  const [isDescriptionError, setIsDescriptionError] = useState<boolean>(false);
  const [isImageError, setIsImageError] = useState<boolean>(false);
  const [isFileError, setIsFileError] = useState<boolean>(false);

  const [uploadConfirmModalOpen, setUploadConfirmModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
    }
    fetchData()
      .catch(console.error);
  }, [])





  const handleDocumentFile = (e: any) => {

    const file = e.target.files![0];
    if (file && fileExtension(file.name.trim()).toUpperCase() === "PDF") {
      let filename = e.target.files[0].name;
      setAttachmentFile(file);
      setDocumentFileName(filename);
    }

  }

  const handleImageFile = (e: any) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      const img = e.target.files![0];
      if (img && img.type.substring(0, 5) === "image") {
        setImage(img);
      }

      setImageFileName(filename)
      setSelectedImage(URL.createObjectURL(e.target.files[0]));

    }
  }

  const fileExtension = (fileName: string) => {
    if (fileName && fileName != '')
      return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    else
      return ''
  }

  function CloseSubmit() {
    props.handleClose(false);
  }


  const handleCancel = () => {
    setUploadConfirmModalOpen(false);
  }

  async function handleChbx(chbx: string) {
    setCabinChecbox(false)
    setChassisCheckBox(false)
    setOtherCehckbox(false)
    setDriveCehckbox(false)

    if (chbx == 'Kabin') {
      setCabinChecbox(true)
    }

    else if (chbx == 'Şasi') {
      setChassisCheckBox(true)
    }

    else if (chbx == 'Diğer') {
      setOtherCehckbox(true)
    }

    else if (chbx == 'Sürüş') {
      setDriveCehckbox(true)
    }
  }

  async function handleFormSubmit() {
    try {
      setLoading(true);
      let errorExist: boolean = false;
      if (!driveCehckbox && !otherCehckbox && !chassisCheckBox && !cabinChecbox) {
        setIsCheckboxEror(true)
        errorExist = true;
      }
      if (title.length <= 0) {
        setIsTitleError(true)
        errorExist = true;
      }

      if (description.length <= 0) {
        setIsDescriptionError(true)
        errorExist = true;
      }
      if (!image) {
        setIsImageError(true);
        errorExist = true;
      }
      if (!attachmentFile) {
        setIsFileError(true);
        errorExist = true;
      }

      if (errorExist) {
        return;
      }
      if (image && attachmentFile) {
        const formData = new FormData();

        formData.append('VehicleType', props.vehicleType.toString());
        if (chassisCheckBox) { formData.append('Section', 'Chassis'); }
        else if (otherCehckbox) { formData.append('Section', 'Other'); }
        else if (driveCehckbox) { formData.append('Section', 'Driving'); }
        else if (cabinChecbox) { formData.append('Section', 'Cabin'); }
        formData.append('Title', title);
        formData.append('Description', description);
        formData.append('IntroCardImage', image);
        formData.append('IntroCardDocument', attachmentFile);

        var result = await createCard(formData).then(() => {
          props.handleClose();
          toast.success("Tanıtım Broşürü başarıyla eklendi.")
        });
        await introCardStore.loadIntroCards();
      }
    }
    catch (error) {
      //console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>

      <div className="modalWrapper">
        <div className='' style={{ width: '100%', padding: '0 20px' }}>

          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Başlık </div>
          <InputErrorMessage inError={isTitleError} error='Başlık boş bırakılamaz !' />
          <input type="text" maxLength={100} name="" style={{ margin: '10px 0', width: '100%' }} id="" placeholder='Bir başlık giriniz'
            onChange={(event) => { setTitle(event.target.value); setIsTitleError(false); }} value={(title == null) ? '' : title} />
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0.8rem 0 0.2rem  0' }}>Açıklama</div>
          <InputErrorMessage inError={isDescriptionError} error='Açıklama boş bırakılamaz !' />
          <input type="text" maxLength={100} name="" style={{ margin: '10px 0', width: '100%' }} id="" placeholder='Bir açıklama giriniz'
            onChange={(event) => { setDescription(event.target.value); setIsDescriptionError(false); }} value={(description == null) ? '' : description} />

        </div>

        <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column' }}>
          <div style={{ marginLeft: '140px', marginBottom:'10px' }}>
            <InputErrorMessage inError={isImageError} error='Resim boş bırakılamaz !' />
          </div>
          <label htmlFor="picInput"
            style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
            className="btn">
            <div className="entryModalButton dark-gray-button">
              Resim Ekle
              <i style={{ margin: '0 5px ' }} className="picture icon"></i>
              <input className='button dark-gray-button'
                id='picInput'
                style={{ height: '40px', width: '250px', color: 'white', visibility: 'hidden' }}
                onChange={(event) => { handleImageFile(event); setIsImageError(false); }}
                type='file'
                accept="image/*"
              />
            </div>
          </label>

          {selectedImage ?
            <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column' }}>
              <div className="selectedBorderBox" style={{ display: 'flex', justifyContent: 'center', padding: '0px 20px 5px 20px ', flexDirection: 'column' }}>
                <div className="selectedFileDesc" style={{ padding: '10px' }}>
                  <div className="selectedFileName" >Dosya Adı : <b> {imageFileName}</b></div>
                </div>
                <img alt="preview image" style={{ maxHeight: '220px' }} src={selectedImage} />
              </div>

            </div>
            : <div />}
        </div>


        <div className='' style={{ padding: '20px' }}>
          <div className='EntryHeader' style={{ textAlign: 'left' }}>Bölüm</div>
          <InputErrorMessage inError={isCheckboxEror} error='Bölüm seçimi boş bırakılamaz !' />
          <table style={{ padding: "10px" }}>
            <tbody>
              <tr>
                <td>Kabin</td>
                <td>
                  <label className="switch">
                    <input onChange={(event) => { handleChbx("Kabin"); setIsCheckboxEror(false); }} type="checkbox" checked={cabinChecbox} />
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Sürüş</td>
                <td>
                  <label className="switch">
                    <input onChange={(event) => { handleChbx("Sürüş"); setIsCheckboxEror(false); }} type="checkbox" checked={driveCehckbox} />
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Şasi</td>
                <td>
                  <label className="switch">
                    <input onChange={(event) => { handleChbx("Şasi"); setIsCheckboxEror(false); }} type="checkbox" checked={chassisCheckBox} />
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td>Diğer</td>
                <td>
                  <label className="switch">
                    <input onChange={(event) => { handleChbx("Diğer"); setIsCheckboxEror(false); }} type="checkbox" checked={otherCehckbox} />
                    <span className="slider round"></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column' }}>
          <div style={{ marginLeft: '140px', marginBottom:'10px' }}>
            <InputErrorMessage inError={isFileError} error='Dosya boş bırakılamaz !' />
          </div>
          <label htmlFor="documentInput"
            style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
            className="btn">
            <div className="entryModalButton dark-gray-button">
              Dosya Seç
              <i style={{ margin: '0 5px ' }} className="file icon"></i>
              <input className='button dark-gray-button' id='documentInput'
                style={{ height: '40px', width: '250px', color: 'white', visibility: 'hidden' }}
                onChange={(event) => { handleDocumentFile(event); setIsFileError(false); }}
                accept="application/pdf"
                type='file' />
            </div>
          </label>
          {documentFileName != '' ?
            <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column' }}>
              <div className="selectedBorderBox" >
                <div className="selectedFileDesc" style={{ padding: '10px' }}>
                  <div className="selectedFileName">Dosya Adı : <b> {documentFileName}</b></div>
                </div>
              </div>
            </div>
            : <div />}
        </div>
      </div>
      <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '20px ' }}>
        <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Kapat' />
        {/* {documentFileName != '' && imageFileName != ''  ? */}
        <input className='button mr20 confirm-button' onClick={() => { handleFormSubmit(); }} type='submit' value='Yükle' />
        {/* : ''} */}

      </div>
    </>


  )

})