import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm, Dropdown } from 'semantic-ui-react';
import { toast } from 'react-toastify'
import { useStore } from '../../../app/stores/store';
import InputErrorMessage from '../../components/inputErrorMessage';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { history } from "../../..";
import ReactDatePicker from 'react-datepicker';
import { tr } from 'date-fns/locale';
import { RemoveNotificationDTO } from '../../../app/models/models';

interface Props {
  handleClose: any;
  elementId: string;
}

export default observer(function CreateNotification(props: Props) {
  const { notificationStore } = useStore();
  const { createNotification } = notificationStore;

  const [title, setTitle] = useState('');
  const [isNameError, setIsNameError] = useState<boolean>(false);

  const [vehicleDropdown, setVehicleDropdown] = useState('');
  const [status, setStatus] = useState('');
  const [profileDropdown, setProfileDropdown] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isVehicleDropdownError, setIsVehicleDropdownError] = useState<boolean>(false);
  const [isProfileDropdownError, setIsProfileDropdownError] = useState<boolean>(false);
  const [isUsersError, setIsUsersError] = useState<boolean>(false);

  const [content, setContent] = useState('');
  const [users, setUsers] = useState('');
  const [isContentError, setIsContentError] = useState<boolean>(false);
  const [isSendDateError, setIsSendDateError] = useState<boolean>(false);
  const [isUserTypeError, setIsUserTypeError] = useState<boolean>(false);

  const [ownersCbx, setOwnersCbx] = useState(false);
  const [serviceManagersCbx, setServiceManagersCbx] = useState(false);
  const [driversCbx, setDriversCbx] = useState(false);
  const [sendDate, setSendDate] = useState<any>('');

  const [cancelConfirmModalOpen, setCancelConfirmModalOpen] = useState<boolean>(false);
  const [elementId, setElementId] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      setProfileDropdown("T");
      const data = await notificationStore.getNotificationDetail(props.elementId)
      if (data) {
        setTitle(data.title);
        setContent(data.content);
        setVehicleDropdown(data.vehicleType == "Kamyon" ? "Truck" : "Bus");
        setProfileDropdown(data.profile);
        console.log(data.profile);
        setOwnersCbx(data.owners);
        setDriversCbx(data.drivers);
        setServiceManagersCbx(data.serviceManagers);
        setUsers(data.notificationDetailUserString)
        setStatus(data.status);
        let sdate = new Date(data.dateToSend)
        setSendDate(sdate);
      }

    }
    fetchData()
      .catch(console.error);
  }, [])

  function CloseSubmit() {
    props.handleClose(false);
  }



  const handleCancel = () => {
    setCancelConfirmModalOpen(false);
  }

  async function handleExport() {
    try {
      let exp = new RemoveNotificationDTO();
      exp.id = props.elementId;
      await notificationStore.excelExport('/notification/export', props.elementId, title);
    }
    catch (error) {
      //console.log(error);
    }
    finally {
      setLoading(false);
    }
  }


  const handleRemoveModal = (id: string) => {
    setElementId(id);
    setCancelConfirmModalOpen(true);
  }

  async function ConfirmCancelSubmit() {
    try {
      let rid = new RemoveNotificationDTO();
      rid.id = elementId;
      var res = await notificationStore.delete(rid);
      if (res === 200) {
        toast.success('Notification başarıyla kaldırıldı.')
        props.handleClose();
        setCancelConfirmModalOpen(false);
        await notificationStore.loadNotifications;

      }
      else {
        toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ")
      }

    }
    catch (error) {
      //console.log(error);
    }
  }

  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>
      <Confirm className='RemoveConfirmModal'
        open={cancelConfirmModalOpen}
        content="Bildirimi kaldırmak istiyor musunuz?"
        cancelButton='Vazgeç'
        confirmButton="Tamam"
        onCancel={handleCancel}
        onConfirm={() => { ConfirmCancelSubmit(); }}
        size='mini'
      />
      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="topWrap" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="inputWrap" style={{ padding: '0 20px', width: '80%' }}>
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Başlık </div>
            <InputErrorMessage inError={isNameError} error='Bu alan boş bırakılamaz!' />
            <input type="text" disabled maxLength={100} name="" style={{ margin: '10px 0', width: '100%' }} id=""
              onChange={(event) => { setTitle(event.target.value); setIsNameError(false); }} value={(title == null) ? '' : title} />
          </div>
          <div className="vehicleDropdown " style={{ width: '180px', marginRight: '15px' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0', }}>Ürün Grubu</div>
            <InputErrorMessage inError={isVehicleDropdownError} error='Bu alan boş bırakılamaz!' />
            <div className="" style={{ height: '10px' }}>
              <Dropdown style={{
                width: '180px',
                backgroundColor: 'rgb(242,242,242)',
                height: '36px',
                margin: '9px 0',
              }}
                placeholder='Seçiniz'
                fluid
                selection
                disabled
                options={[{ key: 'truck', text: "Kamyon", value: "Truck" },
                { key: 'bus', text: "Otobüs", value: "Bus" },
                ]}
                value={vehicleDropdown}
                onChange={(event, { value }) => {
                  if (value != null) {
                    setVehicleDropdown(value.toString());
                    setIsVehicleDropdownError(false);
                  }
                }}
              />

            </div>

          </div>
        </div>

        <div className="inputWrap" style={{ padding: '0 20px', width: '100%' }}>
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Metin İçeriği </div>
          <InputErrorMessage inError={isContentError} error='Bu alan boş bırakılamaz!' />
          <textarea name="" disabled style={{ margin: '10px 0', width: '100%', height: '72px', resize: 'none' }} id=""
            onChange={(event) => { setContent(event.target.value); setIsContentError(false); }} value={(content == null) ? '' : content} />
        </div>
        <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', margin: '10px 0', flexDirection: 'column', alignItems: 'center' }}>
          <div className="profileDropdown " style={{ width: '180px', marginRight: '15px' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0', }}>Profil</div>
            <InputErrorMessage inError={isProfileDropdownError} error='Bu alan boş bırakılamaz!' />
            <div className="" style={{ height: '10px' }}>
              <Dropdown style={{
                width: '180px',
                backgroundColor: 'rgb(242,242,242)',
                height: '36px',
                margin: '9px 0',
              }}
                placeholder='Seçiniz'
                fluid
                selection
                disabled
                options={[{ key: 'all', text: "Tüm Kullanıcılar", value: "A" },
                { key: 'determined', text: "Belirli Kullanıcılar", value: "D" },
                ]}
                value={profileDropdown}
                onChange={(event, { value }) => {
                  if (value != null) {
                    setProfileDropdown(value.toString());
                    setIsProfileDropdownError(false);
                  }
                }}
              />
            </div>
          </div>
          <div className="" style={{ marginTop: '50px' }}></div>
          {profileDropdown == 'A' ?
            <div className="table">
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Kullanıcı Tipi </div>
              <InputErrorMessage inError={isUserTypeError} error='En az bir seçim yapmalısınız' />
              <table style={{ padding: "10px 0" }} className="serviceTable">
                <tbody>
                  <tr>
                    <td>Araç Sahipleri</td>
                    <td>
                      <label className="switch">
                        <input disabled checked={ownersCbx} onChange={(event) => { setOwnersCbx(event.target.checked); !ownersCbx && setIsUserTypeError(false); }} name="ownersCbx" type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Servis Süreçleri Yetkilileri</td>
                    <td>
                      <label className="switch">
                        <input disabled checked={serviceManagersCbx} onChange={(event) => { setServiceManagersCbx(event.target.checked); !serviceManagersCbx && setIsUserTypeError(false); }} name="serviceManagersCbx" type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>Sürücüler</td>
                    <td>
                      <label className="switch">
                        <input disabled checked={driversCbx} onChange={(event) => { setDriversCbx(event.target.checked); !driversCbx && setIsUserTypeError(false); }} name="driversCbx" type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            : profileDropdown == 'D' &&
            <div className="inputWrap" style={{ padding: '0 20px', width: '100%' }}>
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Bildirim Gönderilecek Kullanıcıların Cep Telefonları</div>
              <InputErrorMessage inError={isUsersError} error='Bu alan boş bırakılamaz!' />
              <textarea name="" disabled style={{ margin: '10px 0', width: '100%', height: '72px', resize: 'none' }} id=""
                onChange={(event) => { setUsers(event.target.value); setIsUsersError(false); }} value={(users == null) ? '' : users} />
            </div>
          }
        </div>
      </div>
      <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px ' }}>
        <div className="Senddate" style={{ display: 'flex', flexDirection: 'column', margin: '0  20px', width: '45%' }} >
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Yayınlanma Tarihi</div>
          <div style={{ marginBottom: '0px' }}>
            <InputErrorMessage inError={isSendDateError} error='Bu alan boş bırakılamaz!' />
          </div>
          <div className="sDates" style={{ display: 'flex', margin: '10px 0' }}>
            <div className="ui input icon" style={{ width: '100%' }} >
              <ReactDatePicker
                locale={tr}
                timeInputLabel="Time:"
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat='p'
                showTimeInput
                disabled
                name='FinishDate'
                placeholderText='gg.AA.YYYY SS:dd'
                selected={sendDate}
                onChange={(date) => {
                  setSendDate(date);
                  setIsSendDateError(false);
                }}
                className="dateAreaStory"
              />
              <i className="calendar icon" ></i>
            </div>

          </div>
        </div>
        <div className="" style={{ display: 'flex', height: '36px', marginTop: '34px', float: 'right' }}>
          <input className='button mr20 history-button' onClick={() => { handleExport(); }} type='submit' value='Excele Aktar' />

          <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Kapat' />
          {status == 'A' &&
            <input className='button mr20 reject-button' onClick={() => { handleRemoveModal(props.elementId); }} type='submit' value='Kaldır' />
          }

        </div>
      </div>
    </>


  )

})
