import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm, Dropdown, Label } from 'semantic-ui-react';
import { toast } from 'react-toastify'
import { useStore } from '../../../app/stores/store';
import InputErrorMessage from '../../components/inputErrorMessage';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { history } from "../../..";
import ReactDatePicker from 'react-datepicker';
import { tr } from 'date-fns/locale';

interface Props {
  handleClose: any;
}

export default observer(function CreateStory(props: Props) {
  const { storyStore } = useStore();
  const { createStory } = storyStore;



  const [startDate, setStartDate] = useState<any>('');
  const [finishDate, setFinishDate] = useState<any>('');


  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isNameError, setIsNameError] = useState<boolean>(false);

  const [uploadConfirmModalOpen, setUploadConfirmModalOpen] = useState<boolean>(false);
  const [image, setImage] = useState<File>();
  const [thumbnail, setThumbnail] = useState<File>();
  const [videoFileName, setVideoFileName] = useState('');
  const [imageFileName, setImageFileName] = useState('');
  const [thumbnailFileName, setThumbnailFileName] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [videoDuration, setVideoDuration] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [selectedThumbnail, setSelectedThumbnail] = useState('');
  const [attachmentFile, setAttachmentFile] = useState<File>();
  const [productDropdown, setProductDropdown] = useState('');
  const [isThumbnailError, setIsThumbnailError] = useState<boolean>(false);
  const [isStartDateError, setIsStartDateError] = useState<boolean>(false);
  const [isFinishDateError, setIsFinishDateError] = useState<boolean>(false);
  const [isProductDropdownError, setIsProductDropdownError] = useState<boolean>(false);
  const [isImageError, setIsImageError] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {

    }
    fetchData()
      .catch(console.error);
  }, [])

  function CloseSubmit() {
    props.handleClose(false);
  }



  const handleCancel = () => {
    setUploadConfirmModalOpen(false);
  }


  async function handleDate(date: any) {
    const newDate = new Date(date);
    let res = new Date(newDate.getTime() + (1000 * 60 * 60 * 3));
    return res;
  }



  async function handleFormSubmit() {
    try {
      setLoading(true);
      let errorExist: boolean = false;
      const formData = new FormData();

      if (title.length <= 0) {
        setIsNameError(true)
        errorExist = true;
      }

      if (startDate.length <= 0) {
        setIsStartDateError(true)
        errorExist = true;
      }
      if (finishDate.length <= 0) {
        setIsFinishDateError(true)
        errorExist = true;
      }
      if (productDropdown.length <= 0) {
        setIsProductDropdownError(true)
        errorExist = true;
      }
      if (!image) {
        if (!attachmentFile && !thumbnail) {
          setIsImageError(true);
          errorExist = true;
        }
        if (attachmentFile && !thumbnail) {
          setIsThumbnailError(true);
          errorExist = true;
        }
      }

      if (errorExist) {
        return;
      }



      if (true) {
        formData.append('VehicleType', productDropdown);
        formData.append('Name', title);
        formData.append('FileDuration', videoDuration.toString());

        if (image && !attachmentFile) {


          formData.append('Image', image);
          formData.append('Thumbnail', '');
        }
        if (!image && attachmentFile && thumbnail) {
          formData.append('Image', attachmentFile);
          formData.append('Thumbnail', thumbnail);
        }
        let fdate = (await handleDate(finishDate)).toISOString()
        let sdate = (await handleDate(startDate)).toISOString()
        formData.append('StartDate', sdate);
        formData.append('FinishDate', fdate);


        var result = await createStory(formData).then(() => {
          props.handleClose();
          toast.success('Story başarıyla eklendi.')
        });
        await storyStore.loadStories();
      }
    }
    catch (error) {
      //console.log(error);
    }
    finally {
      setLoading(false);
    }
  }




  const fileExtension = (fileName: string) => {
    if (fileName && fileName != '')
      return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    else
      return ''
  }



  const handleFile = (e: any) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      const file = e.target.files![0];

      if (file && file.type.substring(0, 5) === "image") {
        if (file.size > 3000000) {
          toast.error("Seçilen resmin boyutu 3MB'ın üzerindedir");
        }
        else {
          setImage(file);
          setImageFileName(filename)
          setSelectedImage(URL.createObjectURL(e.target.files[0]));
          setAttachmentFile(undefined);
          setVideoFileName('');
          setSelectedVideo('');
          setThumbnail(undefined);
          setSelectedThumbnail('');
          setThumbnailFileName('');
        }
      }
      else if (file && fileExtension(file.name.trim()).toUpperCase() === "MP4") {
        // if (file.size > 5000000) {
        //   toast.error("Seçilen videonun boyutu 5MB'ın üzerindedir");
        // }
        // else {
          let filename = e.target.files[0].name;
          setAttachmentFile(file);
          setVideoFileName(filename);
          setSelectedVideo(URL.createObjectURL(e.target.files[0]));
          setImage(undefined);
          setImageFileName('')
          setSelectedImage('');
        // }
      }
    }
  }

  const handleThumbnail = (e: any) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      const file = e.target.files![0];
      if (file && file.type.substring(0, 5) === "image") {
        setThumbnail(file);
        setThumbnailFileName(filename)
        setSelectedThumbnail(URL.createObjectURL(e.target.files[0]));
      }

    }
  }

  const handleDuration = (e: any) => {

    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      const file = e.target.files![0];

      var blobObj = new Blob([file!], { type: "video/mp4" });
      const url = URL.createObjectURL(blobObj);


      return new Promise((resolve) => {
        const video = document.createElement("video");
        video.muted = true;
        const source = document.createElement("source");
        source.src = url; //--> blob URL
        source.type = 'video/mp4'; //--> blob URL
        video.preload = "metadata";
        video.appendChild(source);

        video.onloadedmetadata = function () {
          resolve(video.duration)
          setVideoDuration(Math.floor(video.duration));
          if (video.duration > 30) {
            toast.error("Seçilen videonun süresi 30 saniyenin üzerindedir");
            setAttachmentFile(undefined);
            setVideoFileName('');
            setSelectedVideo('');
            setThumbnail(undefined);
            setSelectedThumbnail('');
            setThumbnailFileName('');
          }
        };
      });
    }


  }





  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>

      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="topWrap" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="inputWrap" style={{ padding: '0 20px', width: '80%' }}>
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Story Başlık </div>
            <InputErrorMessage inError={isNameError} error='Bu alan boş bırakılamaz!' />
            <input type="text" maxLength={100} name="" style={{ margin: '10px 0', width: '100%' }} id=""
              onChange={(event) => { setTitle(event.target.value); setIsNameError(false); }} value={(title == null) ? '' : title} />
          </div>

     

          <div className="productDropdown " style={{ width: '180px', marginRight: '15px' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0', }}>Ürün Grubu</div>
            <InputErrorMessage inError={isProductDropdownError} error='Bu alan boş bırakılamaz!' />
            <div className="" style={{ height: '10px' }}>
              <Dropdown style={{
                width: '180px',
                backgroundColor: 'rgb(242,242,242)',
                height: '36px',
                margin: '9px 0',
              }}
                placeholder='Seçiniz'
                fluid
                selection
                options={[{ key: 'truck', text: "Kamyon", value: "Truck" },
                { key: 'bus', text: "Otobüs", value: "Bus" },
                ]}
                value={productDropdown}
                onChange={(event, { value }) => {
                  if (value != null) {
                    setProductDropdown(value.toString());
                    setIsProductDropdownError(false);
                  }
                }}
              />

            </div>

          </div>
        </div>

        <div className="dates" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="Startdate" style={{ display: 'flex', flexDirection: 'column', margin: '0  20px', width: '45%' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Yayınlanma Tarihi</div>
            <div style={{ marginBottom: '0px' }}>
              <InputErrorMessage inError={isStartDateError} error='Bu alan boş bırakılamaz!' />
            </div>
            <div className="sDates" style={{ display: 'flex', margin: '10px 0' }}>
              <div className="ui input icon" style={{ width: '100%' }} >
                <ReactDatePicker
                  locale={tr}
                  timeInputLabel="Time:"
                  dateFormat="dd/MM/yyyy HH:mm"
                  timeFormat='p'
                  showTimeInput
                  name='FinishDate'
                  placeholderText='gg.AA.YYYY SS:dd'
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setIsStartDateError(false);
                  }}
                  className="dateAreaStory"
                />
                <i className="calendar icon" ></i>
              </div>

            </div>
          </div>
          <div className="Enddate" style={{ display: 'flex', flexDirection: 'column', margin: '0  20px', width: '45%' }} >
            <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Kaldırılma Tarihi</div>
            <div style={{ marginBottom: '0px' }}>
              {finishDate.length > 0 && setIsFinishDateError(false)}
              <InputErrorMessage inError={isFinishDateError} error='Bu alan boş bırakılamaz!' />
            </div>
            <div className="eDates" style={{ display: 'flex', margin: '10px 0' }}>
              <div className="ui input icon" style={{ width: '100%' }} >
                <ReactDatePicker

                  locale={tr}
                  timeInputLabel="Time:"
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeInput
                  name='StartDate'
                  timeFormat="HH:mm"
                  placeholderText='gg.AA.YYYY SS:dd'
                  selected={finishDate}
                  onChange={(date) => {
                    setFinishDate(date);
                    setIsFinishDateError(false);
                  }}
                  className="dateAreaStory"
                />
                <i className="calendar icon" ></i>
              </div>
            </div>
          </div>

        </div>

        <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', margin: '10px 0', flexDirection: 'column' }}>
          <div style={{ marginLeft: '195px', marginBottom: '10px' }}>
            <InputErrorMessage inError={isImageError} error='Lütfen bir dosya seçin!' />
          </div>
          <label htmlFor="picInput"
            style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
            className="btn">
            <div className="entryModalButton dark-gray-button">
              Resim veya Video Ekle
              <i style={{ margin: '0 5px ' }} className="file icon"></i>
              <input className='button dark-gray-button'
                id='picInput'
                style={{ height: '40px', width: '250px', color: 'white', visibility: 'hidden' }}
                onChange={(event) => { handleFile(event); setIsImageError(false); handleDuration(event); }}
                type='file'
                accept="image/*,video/mp4"
              />
            </div>
          </label>

          {selectedImage ?
            <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column', marginLeft: '50px' }}>
              <div className="selectedBorderBox" style={{ display: 'flex', justifyContent: 'center', padding: '0px 20px 5px 20px ', flexDirection: 'column' }}>
                <div className="selectedFileDesc" style={{ padding: '10px' }}>
                  <div className="selectedFileName" >Dosya Adı : <b>
                    {imageFileName.length > 19 ? imageFileName.substring(0, 10) + '...' + imageFileName.substring(imageFileName.length - 5) : imageFileName}</b></div>
                </div>
                <img alt="preview image" style={{ maxHeight: '220px' }} src={selectedImage} />
              </div>

            </div>
            : <div />}
          {selectedVideo != '' ?

            <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', flexDirection: 'column', marginLeft: '50px' }}>
              <div className="selectedBorderBox" >
                <div className="selectedFileDesc" style={{ padding: '10px' }}>
                  <div className="selectedFileName">Dosya Adı : <b>
                    {videoFileName.length > 19 ? videoFileName.substring(0, 10) + '...' + videoFileName.substring(videoFileName.length - 5) : videoFileName}</b></div>


                </div>



              </div>
            </div>
            : <div />}

          {selectedVideo != '' &&
            <div className="thumbWrapper" style={{ display: 'flex', justifyContent: 'center', padding: '0 20px', margin: '10px 0', flexDirection: 'column' }} >
              <div style={{ marginLeft: '170px', marginBottom: '10px' }}>
                <InputErrorMessage inError={isThumbnailError} error='Lütfen bir dosya seçin!' />
              </div>
              <label htmlFor="thumbInput"
                style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
                className="btn">
                <div className="entryModalButton dark-gray-button">
                  Thumbnail Ekle
                  <i style={{ margin: '0 5px ' }} className="picture icon"></i>
                  <input className='button dark-gray-button'
                    id='thumbInput'
                    style={{ height: '40px', width: '250px', color: 'white', visibility: 'hidden' }}
                    onChange={(event) => { handleThumbnail(event); setIsThumbnailError(false); }}
                    type='file'
                    accept="image/*"
                  />
                </div>
              </label>
              {selectedThumbnail ?
                <div className='selectedFileWrapper' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <div className="selectedBorderBox" style={{ display: 'flex', justifyContent: 'center', padding: '0px 20px 5px 20px ', flexDirection: 'column', marginLeft: '105px' }}>
                    <div className="selectedFileDesc" style={{ padding: '10px' }}>
                      <div className="selectedFileName" >Dosya Adı : <b>
                        {thumbnailFileName.length > 19 ? thumbnailFileName.substring(0, 10) + '...' + thumbnailFileName.substring(thumbnailFileName.length - 5) : thumbnailFileName}</b></div>

                    </div>
                    <img alt="preview image" style={{ maxHeight: '220px' }} src={selectedThumbnail} />
                  </div>

                </div>
                : <div />}
            </div>
          }
        </div>




      </div>
      <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px ', float: 'right' }}>
        <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Vazgeç' />
        <input className='button mr20 reject-button' onClick={() => { handleFormSubmit(); }} type='submit' value='Ekle' />

      </div>
    </>


  )

})