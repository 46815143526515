import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm, Dropdown } from 'semantic-ui-react';
import { toast } from 'react-toastify'
import { useStore } from '../../../app/stores/store';
import InputErrorMessage from '../../components/inputErrorMessage';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { history } from "../../..";

interface Props {
  handleClose: any;
  elementId: string;
}

export default observer(function Retrofit(props: Props) {
  const { productStore } = useStore();
  const { updateRetrofit } = productStore;
  const [contentType, setContentType] = useState('');
  const [uploadConfirmModalOpen, setUploadConfirmModalOpen] = useState<boolean>(false);
  const [attatchmentFile, setAttatchmentFile] = useState<File>();
  const [attatchmentFileFileName, setAttatchmentFileFileName] = useState('');
  const [selectedAttatchmentFile, setSelectedAttatchmentFile] = useState('');
  const [productDropdown, setProductDropdown] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isProductDropdownError, setIsProductDropdownError] = useState<boolean>(false);
  const [isFileError, setIsFileError] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
    }
    fetchData()
      .catch(console.error);
  }, [])

  function CloseSubmit() {
    props.handleClose(false);
  }

  const handleValidation = () => {
    let errorExist: boolean = false;

    if (productDropdown.length <= 0) {
      setIsProductDropdownError(true)
      errorExist = true;
    }
    if (!attatchmentFile) {
      setIsFileError(true);
      errorExist = true;
    }

    if (errorExist) {
      return;
    }
    setUploadConfirmModalOpen(true);
  }



  const handleCancel = () => {
    setUploadConfirmModalOpen(false);
  }



  async function handleFormSubmit() {
    try {
      setLoading(true);



      const formData = new FormData();

      formData.append('fileUrl', attatchmentFile!);
      formData.append('vehicleType', productDropdown);
      var result = await updateRetrofit(formData).then(() => {
        props.handleClose();
        toast.success('Retrofit başarıyla güncellendi.');
      });
      await productStore.loadProducts();

    }
    catch (error) {
      //console.log(error);
    }
    finally {
      setLoading(false);
    }
  }




  const fileExtension = (fileName: string) => {
    if (fileName && fileName != '')
      return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    else
      return ''
  }



  const handleFile = (e: any) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      const file = e.target.files![0];
      
      if (file && fileExtension(file.name.trim()).toUpperCase() === "PDF") {
        let filename = e.target.files[0].name;
        setContentType('pdf')
        setAttatchmentFile(file);
        setAttatchmentFileFileName(filename);
        setSelectedAttatchmentFile(URL.createObjectURL(e.target.files[0]));
      }
    }
  }

  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>
      <Confirm className='RemoveConfirmModal'
        open={uploadConfirmModalOpen}
        content='Retrofit dosyasını güncellemek istiyor musunuz?'
        cancelButton='Vazgeç'
        confirmButton="Tamam"
        onCancel={handleCancel}
        onConfirm={() => { handleFormSubmit(); }}
        size='mini'
      />
      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="productDropdown " style={{ width: '100%', margin: '0', display: 'inline-block', textAlign: 'center' }} >
          <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0', width: '180px', margin: '0', display: 'inline-block' }}>Ürün Grubu</div>
            <InputErrorMessage inError={isProductDropdownError} error='Bu alan boş bırakılamaz!' />

          <div className="" style={{ height: '10px' }}>
            <Dropdown style={{
              width: '180px',
              backgroundColor: 'rgb(242,242,242)',
              height: '36px',
              margin: '9px 0',
              display: 'inline-block',
            }}
              placeholder='Seçiniz'
              fluid
              selection
              options={[{ key: 'truck', text: "Kamyon", value: "Truck" },
              { key: 'bus', text: "Otobüs", value: "Bus" },
              ]}
              value={productDropdown}
              onChange={(event, { value }) => {
                if (value != null) {
                  setProductDropdown(value.toString());
                  setIsProductDropdownError(false);
                }
              }}
            />

          </div>

        </div>
        <div style={{ marginTop: '50px' }}>
          <div className="" style={{ marginLeft:'115px', marginBottom:'10px' }}>
          <InputErrorMessage inError={isFileError} error='Lütfen bir dosya seçin!' />
          </div>
          <label htmlFor="picInput"
            style={{ height: '40px', width: '180px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
            className="btn">
            <div className="entryModalButton dark-gray-button">
              Dosya Ekle
              <i style={{ margin: '0 5px ' }} className="file icon"></i>
              <input className='button dark-gray-button'
                id='picInput'
                style={{ height: '40px', width: '140px', color: 'white', visibility: 'hidden' }}
                onChange={(event) => { handleFile(event); setIsFileError(false); }}
                type='file'
                accept="application/pdf"
              />
            </div>
          </label>



          {selectedAttatchmentFile ?
            <div className='selectedFileWrapper' style={{ display: 'inline-block', justifyContent: 'center', padding: '0', margin: '0', width: '100%', textAlign: 'center' }}>
              <div className="selectedBorderBox" style={{ display: 'inline-block', justifyContent: 'center', padding: '0 ', margin: '0', width: '180px ' }}>
                <div className="selectedFileDesc" style={{ padding: '10px', width: '180px' }}>
                  <div className="selectedFileName" >Dosya Adı : <b>
                    {attatchmentFileFileName.length > 19 ? attatchmentFileFileName.substring(0, 10) + '...' + attatchmentFileFileName.substring(attatchmentFileFileName.length - 5) : attatchmentFileFileName}</b></div>
                </div>
              </div>

            </div>
            : <div />}


        </div>





        <div className="ui large buttons" style={{ marginTop: '20px' }}>
          <a target="_blank" className="ui button" href="https://stcpdocstest.blob.core.windows.net/product/retrofit_bus.pdf"><i className="download icon"></i>Otobüs</a>
          <div className="or"></div>
          <a target="_blank" className="ui button" href="https://stcpdocstest.blob.core.windows.net/product/retrofit_truck.pdf"><i className="download icon"></i> Kamyon</a>
        </div>



      </div>
      <div className='' style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px ' }}>

        <div className="" style={{ display: 'flex', height: '36px', marginTop: '34px' }}>

          <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Kapat' />
          <input className='button mr20 confirm-button' onClick={() => { handleValidation(); }} type='submit' value='Güncelle' />

        </div>
      </div>
    </>


  )

})
