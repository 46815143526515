import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Confirm, Dropdown } from 'semantic-ui-react'
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify'
import { history } from "../..";
import InputErrorMessage from '../components/inputErrorMessage';
import LoadingComponent from '../../app/layout/LoadingComponent';

interface Props {
  handleClose: any;
  elementId: string;
}

export default observer(function UpdateRestingPlace(props: Props) {
  const { restingPlaceStore } = useStore();
  const { updateRestingPlace } = restingPlaceStore;


  const [restingPlaceName, setRestingPlaceName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');
  const [closingTime, setClosingTime] = useState('');
  const [openingTime, setOpeningTime] = useState('');
  const [isNameError, setIsNameError] = useState<boolean>(false);
  const [isClosingTimeError, setIsClosingTimeError] = useState<boolean>(false);
  const [isOpeningTimeError, setIsOpeningTimeError] = useState<boolean>(false);
  const [isPhoneError, setIsPhoneError] = useState<boolean>(false);
  const [isLongitudeError, setIsLongitudeError] = useState<boolean>(false);
  const [isLatitudeError, setIsLatitudeError] = useState<boolean>(false);
  const [isDiscountRateError, setIsDiscountRateError] = useState<boolean>(false);
  const [isUrlError, setIsUrlError] = useState<boolean>(false);

  const [uploadConfirmModalOpen, setUploadConfirmModalOpen] = useState<boolean>(false);

  const [washingMachineCBx, setWashingMachineCBx] = useState(false);
  const [washingMachineId, setWashingMachineId] = useState('f0de1bd7-fd39-4441-cc6d-08dbad3400cb');
  const [restaurantCbx, setRestaurantCbx] = useState(false);
  const [restaurantId, setRestaurantId] = useState('f0de1bd7-fd39-4441-cc6d-08dbad3400ba');
  const [truckParkCbx, setTruckParkCbx] = useState(false);
  const [truckParkId, setTruckParkId] = useState('f0de1bd7-fd39-4441-cc6d-08dbad3400ab');
  const [bathroomCbx, setBathroomCbx] = useState(false);
  const [bathroomId, setBathroomId] = useState('f0de1bd7-fd39-4441-cc6d-08dbad3400aa');
  const [showerCbx, setShowerCbx] = useState(false);
  const [showerId, setShowerId] = useState('f0de1bd7-fd39-4441-cc6d-08dbad3400ad');
  const [hotelCbx, setHotelCbx] = useState(false);
  const [hotelId, setHotelId] = useState('f0de1bd7-fd39-4441-cc6d-08dbad3400be');
  const [wifiCbx, setWifiCbx] = useState(false);
  const [wifiId, setWifiId] = useState('f0de1bd7-fd39-4441-cc6d-08dbad3400ae');
  const [tvCbx, setTvCbx] = useState(false);
  const [tvId, setTvId] = useState('f0de1bd7-fd39-4441-cc6d-08dbad3400ac');

  const [discountCbx, setDiscountCbx] = useState(false);
  const [image1, setImage1] = useState<File>();
  const [image2, setImage2] = useState<File>();
  const [image3, setImage3] = useState<File>();
  const [image1FileName, setImage1FileName] = useState('');
  const [image2FileName, setImage2FileName] = useState('');
  const [image3FileName, setImage3FileName] = useState('');
  const [selectedImage1, setSelectedImage1] = useState('');
  const [selectedImage2, setSelectedImage2] = useState('');
  const [selectedImage3, setSelectedImage3] = useState('');
  const [url, setUrl] = useState('');
  const [isImageError, setIsImageError] = useState<boolean>(false);
  const [discountRate, setDiscountRate] = useState('');
  const [regionDropdown, setRegionDropdown] = useState('');
  const [isRegionDropdownError, setIsRegionDropdownError] = useState<boolean>(false);


  const [serviceList, setServiceList] = useState<{ RestingPlaceServiceTypeId: string; }[]>([]);



  useEffect(() => {
    const fetchData = async () => {
      const data = await restingPlaceStore.getRestingPlaceDetail(props.elementId);
      if (data) {
        setRestingPlaceName(data.name);
        setLatitude(data.latitude.toString().replace('.', ','));
        setLongitude(data.longitude.toString().replace('.', ','));
        setPhoneNumber(data.phone);
        setOpeningTime(data.openingTime);
        setClosingTime(data.closingTime);
        setRegionDropdown(data.region);
        setDiscountCbx(data.discountForAppUser);
        setDiscountRate(data.discountPercentageForAppUser);
        setUrl(data.shareUrl);
        if (data.image1 != "https://cpdocs.azureedge.net/restingplaces/default_mola_tesisi.jpg") {
          setImage1FileName(data.image1);
          setSelectedImage1(data.image1);
        }
        if (data.image2 != null) {
          setImage2FileName(data.image2);
          setSelectedImage2(data.image2);
        }

        if (data.image3 != null) {
          setImage3FileName(data.image3);
          setSelectedImage3(data.image3);
        }
        data.serviceList.forEach(element => {
          getServiceTypes(element.restingPlaceServiceType.id)
        });



      }

    }
    fetchData()
      .catch(console.error);
  }, [])

  function CloseSubmit() {
    props.handleClose(false);
  }

  const handleOpeningTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const time = formatTimeInput(value);
    setOpeningTime(time);
    setIsOpeningTimeError(false);
  };

  const handleClosingTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const time = formatTimeInput(value);
    setClosingTime(time);
    setIsClosingTimeError(false);
  };

  const formatTimeInput = (value: string) => {
    let hours = '';
    let minutes = '';

    value = value.replace(":", "")
    if (value.length >= 1) {
      hours = (value[0] === '0' || value[0] === '1' || (value[0] === '2' && (value.length === 1 || '0123'.includes(value[1])))) ? value[0] : '';
    }
    if (value.length >= 2 && hours) {
      hours += value[1];
    }

    if (value.length > 2) {
      if (value.length > 2) {
        minutes += '012345'.includes(value[2]) ? value[2] : '';
      }
      if (value.length > 3) {
        minutes += '0123456789'.includes(value[3]) ? value[3] : '';
      }
    }

    if (value.length > 2)
      return hours + ':' + minutes;

    return hours + minutes;
  };

  async function getServiceTypes(id: any) {
    if (id == 'f0de1bd7-fd39-4441-cc6d-08dbad3400ba') { setRestaurantCbx(true); setRestaurantId('f0de1bd7-fd39-4441-cc6d-08dbad3400ba'); }
    else if (id == 'f0de1bd7-fd39-4441-cc6d-08dbad3400cb') { setWashingMachineCBx(true); setWashingMachineId('f0de1bd7-fd39-4441-cc6d-08dbad3400ab') }
    else if (id == 'f0de1bd7-fd39-4441-cc6d-08dbad3400ab') { setTruckParkCbx(true); setTruckParkId('f0de1bd7-fd39-4441-cc6d-08dbad3400ab') }
    else if (id == 'f0de1bd7-fd39-4441-cc6d-08dbad3400aa') { setBathroomCbx(true); setBathroomId('f0de1bd7-fd39-4441-cc6d-08dbad3400aa') }
    else if (id == 'f0de1bd7-fd39-4441-cc6d-08dbad3400ad') { setShowerCbx(true); setShowerId('f0de1bd7-fd39-4441-cc6d-08dbad3400ad') }
    else if (id == 'f0de1bd7-fd39-4441-cc6d-08dbad3400be') { setHotelCbx(true); setHotelId('f0de1bd7-fd39-4441-cc6d-08dbad3400be') }
    else if (id == 'f0de1bd7-fd39-4441-cc6d-08dbad3400ae') { setWifiCbx(true); setWifiId('f0de1bd7-fd39-4441-cc6d-08dbad3400ae') }
    else if (id == 'f0de1bd7-fd39-4441-cc6d-08dbad3400ac') { setTvCbx(true); setTvId('f0de1bd7-fd39-4441-cc6d-08dbad3400ac') }
  }

  async function handleServiceTypes() {
    if (washingMachineCBx) { serviceList.push({ RestingPlaceServiceTypeId: washingMachineId }); }
    if (restaurantCbx) { serviceList.push({ RestingPlaceServiceTypeId: restaurantId }); }
    if (truckParkCbx) { serviceList.push({ RestingPlaceServiceTypeId: truckParkId }); }
    if (bathroomCbx) { serviceList.push({ RestingPlaceServiceTypeId: bathroomId }); }
    if (showerCbx) { serviceList.push({ RestingPlaceServiceTypeId: showerId }); }
    if (hotelCbx) { serviceList.push({ RestingPlaceServiceTypeId: hotelId }); }
    if (wifiCbx) { serviceList.push({ RestingPlaceServiceTypeId: wifiId }); }
    if (tvCbx) { serviceList.push({ RestingPlaceServiceTypeId: tvId }); }
  }

  const handleCancel = () => {
    setUploadConfirmModalOpen(false);
  }




  async function handleFormSubmit() {
    try {
      setLoading(true);
      let errorExist: boolean = false;

      if (restingPlaceName.length <= 0) {
        setIsNameError(true)
        errorExist = true;
      }
      if (url.length <= 0) {
        setIsUrlError(true)
        errorExist = true;
      }
      if (latitude.length <= 0) {
        setIsLatitudeError(true)
        errorExist = true;
      }
      if (longitude.length <= 0) {
        setIsLongitudeError(true)
        errorExist = true;
      }
      if (phoneNumber.length <= 0) {
        setIsPhoneError(true)
        errorExist = true;
      }
      if (openingTime.length != 5) {
        setIsOpeningTimeError(true)
        errorExist = true;
      }
      if (closingTime.length != 5) {
        setIsClosingTimeError(true)
        errorExist = true;
      }
      if (regionDropdown.length <= 0) {
        setIsRegionDropdownError(true)
        errorExist = true;
      }
      if (!image1FileName && !image2FileName && !image3FileName) {
        setIsImageError(true)
        errorExist = true;
      }
      if (discountCbx && discountRate.length <= 0) {
        setIsDiscountRateError(true)
        errorExist = true;
      }
      if (errorExist) {
        return;
      }
      const formData = new FormData();
      formData.append('Id', props.elementId);


      if (image1FileName && !image2FileName && !image3FileName) {
        formData.append('Image1', image1!);
        formData.append('Image2', '');
        formData.append('Image3', '');
      }
      else if (image1FileName && image2FileName && !image3FileName) {
        formData.append('Image1', image1!);
        formData.append('Image2', image2!);
        formData.append('Image3', '');
      }
      else if (image1FileName && image2FileName && image3FileName) {
        formData.append('Image1', image1!);
        formData.append('Image2', image2!);
        formData.append('Image3', image3!);
      }
      if (true) {
        formData.append('Image1FilePath', image1FileName);
        formData.append('Image2FilePath', image2FileName);
        formData.append('Image3FilePath', image3FileName);

        formData.append('Region', regionDropdown);
        formData.append('Name', restingPlaceName);
        formData.append('Phone', '0' + phoneNumber);
        formData.append('OpeningTime', openingTime.toString());
        formData.append('ClosingTime', closingTime.toString());
        formData.append('Latitude', latitude);
        formData.append('Longitude', longitude);
        formData.append('ShareUrl', url);

        if (discountCbx) {
          formData.append('DiscountForAppUser', "true");
          formData.append('DiscountPercentageForAppUser', discountRate);
        }
        else {
          formData.append('DiscountForAppUser', "false");
          formData.append('DiscountPercentageForAppUser', '0');
        }
        handleServiceTypes();
        formData.append('ServiceList', JSON.stringify(serviceList));


        var result = await updateRestingPlace(formData).then(() => {
          props.handleClose();
          toast.success('Mola yeri başarıyla güncellendi.')
        });
        await restingPlaceStore.loadRestingPlaces();
      }
    }
    catch (error) {
      //console.log(error);
    }
    finally {
      setLoading(false);
    }
  }

  const handlePhoneNumber = (e: any) => {
    let myRate = e.target.value.replace(/0/, '').replace(/\(|\)/g, "").replace(/\D/g, '');
    setPhoneNumber(myRate);

    setIsPhoneError(false);
  }

  const handlePhoneKeyDown = (e: any) => {
    if (phoneNumber.length < 1 && e.key == '0') {
      let mpn = phoneNumber;

    }
  }

  const handleDiscountRate = (e: any) => {
    let myRate = e.target.value.replace(/%/g, '').replace(/\D/g, '');
    setDiscountRate(myRate);
    setIsDiscountRateError(false);
  }

  const handleRemoveImage1 = () => {
    setImage1FileName('');
    setImage1(undefined)
    setSelectedImage1('')
    if (image2FileName?.length > 0) {
      setImage1FileName(image2FileName)
      handleRemoveImage2();
      if (image3FileName?.length > 0) {
        setImage2FileName(image3FileName)
        handleRemoveImage3();
      }
    }
  }
  const handleRemoveImage2 = () => {
    setImage2FileName('');
    setSelectedImage2('');
    setImage2(undefined)
    if (image3FileName?.length > 0) {
      setImage2FileName(image3FileName)
      handleRemoveImage3();
    }
  }
  const handleRemoveImage3 = () => {
    setImage3FileName('');
    setImage3(undefined)
    setSelectedImage3("")
  }

  const handleImageFile = (e: any, imageNumber: number) => {
    if (e.target.files.length > 0) {
      let filename = e.target.files[0].name;
      const img = e.target.files![0];
      if (img && img.type.substring(0, 5) === "image") {
        if (imageNumber == 1) {
          setImage1(img);
          setImage1FileName(filename)
          setSelectedImage1(URL.createObjectURL(e.target.files[0]));
        }
        else if (imageNumber == 2) {
          setImage2(img);
          setImage2FileName(filename)
          setSelectedImage2(URL.createObjectURL(e.target.files[0]));
        }
        else if (imageNumber == 3) {
          setImage3(img);
          setImage3FileName(filename)
          setSelectedImage3(URL.createObjectURL(e.target.files[0]));
        }
      }
    }
  }
  const coordinatesLongKeyUp = (event: any) => {
    event.target.value = event.target.value.replace('.', ',').replace(/[^0-9,]/g, '').replace(/(\..*)\./g, '$1')
    setLongitude(event.target.value);
  }

  const coordinatesLatKeyUp = (event: any) => {
    event.target.value = event.target.value.replace('.', ',').replace(/[^0-9,]/g, '').replace(/(\..*)\./g, '$1')
    setLatitude(event.target.value);
  }

  if (loading) return <div className='modalLoader'><LoadingComponent content='İşlem yapılıyor...' /></div>

  return (
    <>

      <div className="modalWrapper" style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="topside" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="topside60" style={{ width: '60%', padding: '0 20px' }}>
            <div className="inputWrap">
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Mola Yeri Adı </div>
              <InputErrorMessage inError={isNameError} error='Bu alan boş bırakılamaz!' />
              <input type="text" maxLength={100} name="" style={{ margin: '10px 0', width: '100%' }} id=""
                onChange={(event) => { setRestingPlaceName(event.target.value); setIsNameError(false); }} value={(restingPlaceName == null) ? '' : restingPlaceName} />

            </div>

            <div className="coordinatesWrap" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

              <div className="latitude" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Enlem (x)</div>
                <InputErrorMessage inError={isLatitudeError} error='Bu alan boş bırakılamaz!' />
                <input type="text" maxLength={100} name="" style={{ margin: '10px 0', width: '100%' }} id=""
                  onChange={(event) => {
                    setIsLatitudeError(false);
                    setLatitude(event.target.value);
                  }}
                  onKeyUp={coordinatesLatKeyUp}
                  value={(latitude == null) ? '' : latitude} />

              </div>
              <div className="longitude" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Boylam (y)</div>
                <InputErrorMessage inError={isLongitudeError} error='Bu alan boş bırakılamaz!' />
                <input type="text" maxLength={100} name="" style={{ margin: '10px 0', width: '100%' }} id=""
                  onChange={(event) => {
                    setIsLongitudeError(false);
                    setLongitude(event.target.value);
                  }}
                  onKeyUp={coordinatesLongKeyUp}
                  value={(longitude == null) ? '' : longitude} />
              </div>

            </div>

            <div className="inputWrap">
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Mola Yeri Telefon Numarası </div>
              <InputErrorMessage inError={isPhoneError} error='Bu alan boş bırakılamaz!' />
              <input type="text" name="" style={{ margin: '10px 0', width: '100%' }} id="restingPlacePhoneNumber"
                onChange={(event) => { handlePhoneNumber(event) }} onKeyDown={(event) => { handlePhoneKeyDown(event) }}
                maxLength={13}
                value={(phoneNumber == null) ? '0('
                  : phoneNumber.length >= 4 ? '0(' + phoneNumber.substring(0, 3) + ')' + phoneNumber.substring(3,) : '0(' + phoneNumber} />
            </div>

            <div className="timeWrap" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

              <div className="openingTime" style={{ display: 'flex', justifyContent: 'center', padding: '0 20px 0 0', flexDirection: 'column' }}>
                <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Açılış Saati</div>
                <InputErrorMessage inError={isOpeningTimeError} error='Bu alan boş bırakılamaz!' />
                <input
                  style={{ margin: '10px 0', width: '100%' }}
                  type="text"
                  value={openingTime}
                  onChange={handleOpeningTimeChange}
                  placeholder="HH:mm (00:00-23:59)"
                  maxLength={5}
                />
              </div>
              <div className="closingTime" style={{ display: 'flex', justifyContent: 'center',  flexDirection: 'column' }}>
                <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Kapanış Saati</div>
                <InputErrorMessage inError={isClosingTimeError} error='Bu alan boş bırakılamaz!' />
                <input
                  style={{ margin: '10px 0', width: '100%' }}
                  type="text"
                  value={closingTime}
                  onChange={handleClosingTimeChange}
                  placeholder="HH:mm (00:00-23:59)"
                  maxLength={5}
                />
              </div>
            </div>
            <div className="infoText" >
              Çalışma saatlerini 00:00 - 00:00 seçerseniz, uygulamadaki mola yeri detayında "24 Saat Açık" yazacaktır
            </div>
          </div>
          <div className="topside40" style={{ width: '40%', padding: '0 20px', marginTop: '80px' }}>
            <div className="isDiscount">
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0', width: '200px' }}>İndirim Bulunuyor Mu? </div>
              <div className="" style={{ display: 'flex', padding: '10px' }}>
                <label className="switch" style={{ margin: '0 15px' }}>
                  <input checked={discountCbx} onChange={(event) => { setDiscountCbx(event.target.checked); }} name="discountCbx" type="checkbox" />
                  <span className="slider round"></span>
                </label>
                <p>{discountCbx ? 'Evet' : 'Hayır'}</p>
              </div>
              {discountCbx &&
                <div className="discountRate">
                  <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem 0', marginTop: '12px', width: '70%' }}>İndirim Bilgsini Giriniz </div>
                  <InputErrorMessage inError={isDiscountRateError} error='Bu alan boş bırakılamaz!' />
                  <input type="text" name="" style={{ margin: '10px 0', width: '70%' }} id=""
                    onChange={(event) => {
                      handleDiscountRate(event);
                    }} maxLength={3} value={(discountRate == null) ? '%' : '%' + discountRate} />

                </div>
              }

            </div>
          </div>
        </div>
        <div className="botside" style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="botside60" style={{ width: '60%', padding: '0 20px' }}>
            <div className="" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '10px 0' }}>
              <div className="table">
                <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Ortak Hizmetler </div>
                <table style={{ padding: "10px 0" }} className="serviceTable">
                  <tbody>
                    <tr>
                      <td>Çamaşır Yıkama</td>
                      <td>
                        <label className="switch">
                          <input checked={washingMachineCBx} onChange={(event) => { setWashingMachineCBx(event.target.checked); }} name="washingMachineCBx" type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Restorant</td>
                      <td>
                        <label className="switch">
                          <input checked={restaurantCbx} onChange={(event) => { setRestaurantCbx(event.target.checked); }} name="restaurantCbx" type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Tır Parkı</td>
                      <td>
                        <label className="switch">
                          <input checked={truckParkCbx} onChange={(event) => { setTruckParkCbx(event.target.checked); }} name="truckParkCbx" type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Duş</td>
                      <td>
                        <label className="switch">
                          <input checked={showerCbx} onChange={(event) => { setShowerCbx(event.target.checked); }} name="showerCbx" type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Otel</td>
                      <td>
                        <label className="switch">
                          <input checked={hotelCbx} onChange={(event) => { setHotelCbx(event.target.checked); }} name="hotelCbx" type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Tuvalet</td>
                      <td>
                        <label className="switch">
                          <input checked={bathroomCbx} onChange={(event) => { setBathroomCbx(event.target.checked); }} name="bathroomCbx" type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>Wi-Fi</td>
                      <td>
                        <label className="switch">
                          <input checked={wifiCbx} onChange={(event) => { setWifiCbx(event.target.checked); }} name="wifiCbx" type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>TV</td>
                      <td>
                        <label className="switch">
                          <input checked={tvCbx} onChange={(event) => { setTvCbx(event.target.checked); }} name="tvCbx" type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="" style={{marginLeft:'30px'}}>
                <div className="dropdown">
                  <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Bölge Seçiniz</div>
                  <InputErrorMessage inError={isRegionDropdownError} error='Bu alan boş bırakılamaz!' />
                  <div className="regionDropdown " style={{ width: '270px', margin: '10px 0 ' }} >
                    <Dropdown style={{
                      width: '270px',
                      backgroundColor: 'rgb(242,242,242)',
                      height: '43px',
                      margin: '0 auto',
                      fontSize: '16px',
                    }}
                      placeholder='Seçiniz'
                      fluid
                      selection
                      options={[{ key: 'Akdeniz', text: "Akdeniz Bölgesi", value: "Akdeniz" },
                      { key: 'Karadeniz', text: "Karadeniz Bölgesi", value: "Karadeniz" },
                      { key: 'Marmara', text: "Marmara Bölgesi", value: "Marmara" },
                      { key: 'İç Anadolu', text: "İç Anadolu Bölgesi", value: "İç Anadolu" },
                      { key: 'Ege', text: "Ege Bölgesi", value: "Ege" },
                      { key: 'Doğu Anadolu', text: "Doğu Anadolu Bölgesi", value: "Doğu Anadolu" },
                      { key: 'Güneydoğu Anadolu', text: "Güneydoğu Anadolu Bölgesi", value: "Güneydoğu Anadolu" },
                      ]}
                      value={regionDropdown}

                      onChange={(event, { value }) => {
                        if (value != null) {
                          setRegionDropdown(value.toString());
                          setIsRegionDropdownError(false)

                        }
                      }}
                    />
                  </div>
                </div>
                <div className="inputWrap">
                  <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Mola Yeri Linki</div>
                  <InputErrorMessage inError={isUrlError} error='Bu alan boş bırakılamaz!' />
                  <input type="text" name="" style={{ margin: '10px 0', width: '100%' }} id=""
                    onChange={(event) => { setUrl(event.target.value); setIsUrlError(false); }} value={(url == null) ? '' : url} />

                </div>
              </div>

            </div>
          </div>
          <div className="botside40" style={{ width: '40%', padding: '0 20px' }}>
            <div className="" style={{ display: 'flex', flexDirection: 'column', margin: '10px 0' }}>
              <div className='EntryHeader' style={{ textAlign: 'left', padding: '0 0 0.2rem  0' }}>Resim Ekle (Max. 3)</div>
              <div className="img1" style={{ margin: '10px 0' }}>
                {image1FileName?.length != 0 ?
                  <>
                    <div className="selectedFileDesc" style={{ display: 'flex', justifyContent: 'space-between', padding: '2px 0' }}>
                      <div className="selectedFileName" style={{ margin: '5px 0' }} >Dosya Adı: <b>
                        {image1FileName.length > 19 ? image1FileName.substring(0, 10) + '...' + image1FileName.substring(image1FileName.length - 5) : image1FileName}</b></div>
                      <input className='button  reject-button' onClick={() => { handleRemoveImage1(); }} type='submit' value='Kaldır' />
                      {/* <img alt="preview image" style={{ maxHeight: '220px' }} src={selectedImage1} /> */}
                    </div>
                    {image1FileName.includes("https://") &&
                      <a href={image1FileName} className="selectedFileName" style={{ textAlign: 'center', padding: '5px' }}> Resmi İndir</a>
                    }

                  </>
                  :
                  <div className='' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{ position: 'relative', top: '-10px' }}>
                      <InputErrorMessage inError={isImageError} error='Resim boş bırakılamaz!' />
                    </div>
                    <label htmlFor="picInput1"
                      style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
                      className="btn">
                      <div className="entryModalButton dark-gray-button">
                        Resim Ekle
                        <i style={{ margin: '0 5px ' }} className="picture icon"></i>
                        <input className='button dark-gray-button'
                          id='picInput1'
                          style={{ height: '40px', width: '50px', color: 'white', visibility: 'hidden' }}
                          onChange={(event) => { handleImageFile(event, 1); setIsImageError(false); }}
                          type='file'
                          accept="image/*"
                        />
                      </div>
                    </label>


                  </div>

                }

              </div>
              {image1FileName.length != 0 &&
                <div className="img2" style={{ margin: '10px 0' }}>
                  {image2FileName?.length != 0 ?
                    <>
                      <div className="selectedFileDesc" style={{ display: 'flex', justifyContent: 'space-between', padding: '2px 0' }}>
                        <div className="selectedFileName" style={{ margin: '5px 0' }} >Dosya Adı: <b>
                          {image2FileName.length > 19 ? image2FileName.substring(0, 10) + '...' + image2FileName.substring(image2FileName.length - 5) : image2FileName}</b></div>
                        <input className='button  reject-button' onClick={() => { handleRemoveImage2(); }} type='submit' value='Kaldır' />
                      </div>
                      {image2FileName.includes("https://") &&
                        <a href={image2FileName} className="selectedFileName" style={{ textAlign: 'center', padding: '5px' }}> Resmi İndir</a>
                      }
                    </>
                    :
                    <div className='' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                      <label htmlFor="picInput2"
                        style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
                        className="btn">
                        <div className="entryModalButton dark-gray-button">
                          Resim Ekle
                          <i style={{ margin: '0 5px ' }} className="picture icon"></i>
                          <input className='button dark-gray-button'
                            id='picInput2'
                            style={{ height: '40px', width: '50px', color: 'white', visibility: 'hidden' }}
                            onChange={(event) => { handleImageFile(event, 2); setIsImageError(false); }}
                            type='file'
                            accept="image/*"
                          />
                        </div>
                      </label>
                    </div>
                  }
                </div>
              }
              {image2FileName.length != 0 &&
                <div className="img3" style={{ margin: '10px 0' }}>
                  {image3FileName?.length != 0 ?
                    <>
                      <div className="selectedFileDesc" style={{ display: 'flex', justifyContent: 'space-between', padding: '2px 0' }}>
                        <div className="selectedFileName" style={{ margin: '5px 0' }} >Dosya Adı: <b>
                          {image3FileName.length > 19 ? image3FileName.substring(0, 10) + '...' + image3FileName.substring(image3FileName.length - 5) : image3FileName}</b></div>
                        <input className='button  reject-button' onClick={() => { handleRemoveImage3(); }} type='submit' value='Kaldır' />
                      </div>
                      {image3FileName.includes("https://") &&
                        <a href={image3FileName} className="selectedFileName" style={{ textAlign: 'center', padding: '5px' }}> Resmi İndir</a>
                      }

                    </>
                    :
                    <div className='' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                      <label htmlFor="picInput3"
                        style={{ height: '40px', width: '300px', display: 'flex', flexDirection: 'row', margin: 'auto' }}
                        className="btn">
                        <div className="entryModalButton dark-gray-button">
                          Resim Ekle
                          <i style={{ margin: '0 5px ' }} className="picture icon"></i>
                          <input className='button dark-gray-button'
                            id='picInput3'
                            style={{ height: '40px', width: '50px', color: 'white', visibility: 'hidden' }}
                            onChange={(event) => { handleImageFile(event, 3); setIsImageError(false); }}
                            type='file'
                            accept="image/*"
                          />
                        </div>
                      </label>
                    </div>
                  }
                </div>
              }


            </div>

          </div>
        </div>

      </div>
      <div className='' style={{ display: 'flex', justifyContent: 'center', padding: '0 20px ', float: 'right' }}>
        <input className='button mr20 dark-gray-button' onClick={() => { CloseSubmit(); }} type='submit' value='Vazgeç' />
        <input className='button mr20 confirm-button' onClick={() => { handleFormSubmit(); }} type='submit' value='Güncelle' />
      </div>
    </>


  )

})