import { useEffect, useState } from 'react';
import { Confirm } from 'semantic-ui-react';
import { useStore } from '../../app/stores/store';
import { toast } from 'react-toastify';



export default function AppState() {
    const [appStateToggle, setAppStateToggle] = useState<boolean>(true);
    const { appStateStore } = useStore();
    const [changeAppStatePopupOpen, setChangeAppStatePopupOpen] = useState<boolean>(false);
    useEffect(() => {
        const fetchData = async () => {
            const currentState = await appStateStore.getAppState();
            setAppStateToggle(currentState? true : false); 


        }
        fetchData()
            .catch(console.error);
    }, [])
    async function handleChbx() {
        setAppStateToggle(!appStateToggle)
    }

    const handleCancel = () => {
        setChangeAppStatePopupOpen(false);
    }

    async function confirmChangeState() {
        try {
            var res = await appStateStore.setAppState(!appStateToggle)
            if (res === 200) {
                setChangeAppStatePopupOpen(false);
                setAppStateToggle(!appStateToggle)
            }
            else {
                toast.error("İşlem sırasında bir hata meydana geldi. Lütfen tekrar deneyiniz. ")
            }
        }
        catch (error) {
            //console.log(error);
        }
    }
    return (
        <>
            <Confirm className='RemoveConfirmModal'
                open={changeAppStatePopupOpen}
                content={!appStateToggle ? 'Bütün cihazlarda uygulama açılacaktır, devam etmek istiyor musunuz?' : 'Bütün cihazlarda uygulama kapanacaktır, devam etmek istiyor musunuz?'}
                cancelButton='Vazgeç'
                confirmButton="Tamam"
                onCancel={handleCancel}
                onConfirm={() => { confirmChangeState(); }}
                size='mini'
            />


            <div className="appState" style={{ display: 'flex', flexDirection: 'column', margin: '20px' }}>
                <div style={{ fontWeight: '400' }}>
                    Uygulama Durumu:
                </div>
                <div className="labelWrap" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <label className="switch" style={{  marginLeft: '10px', textAlign: 'center' }}>
                        <input onChange={(event) => { setChangeAppStatePopupOpen(true) }} type="checkbox" checked={appStateToggle} />
                        <span className="slider round"></span>
                    </label>
                    <div style={{ fontWeight: '800', marginLeft: '20px', textAlign: 'center' }}>
                        {appStateToggle ? 'Online' : 'Offline'}
                    </div>
                </div>

            </div>
        </>

    )
}